import styled from "../../shared/styled";

export const StyledWrap = styled.div`
    height: 100%;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`;

export const NotFoundTitle = styled.h1`
  font-size: 72px;   
  color: #000;
  font-weight: 600; 
  margin-bottom: 10px; 
`;

export const StyledTitle = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  height: 100vh;
  background: #fff;
`;

export const NotFoundAlert = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: space-around;
`;

export const Logo = styled.img`
  width: 180px;
  margin-bottom: 80px;
  margin: 55px
`;

export const NotFoundImageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #1a56db;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.div<{ bgImage: string }>`
  flex: 1;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ImageTitle = styled.h2`
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1.2;
  color: white;
  span {
    border-bottom: 4px solid #ff6b00;
    padding-bottom: 4px;
  }
`;

export const ImageSubtitle = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-top: 24px;
  color: white;
  opacity: 0.9;
`;

export const ImageText = styled.div`
  position: absolute;
  bottom: 180px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  padding: 0 40px;
`;

export const TurnHomeButton = styled.button.attrs({ type: 'button' })`
  width: 50%;
  height: 48px;
  background:#374E7D;
  color: white;
  border: none;
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  cursor: pointer;
  margin-top: 32px;
  transition: all 0.3s ease;
  
  &:hover {
    color: #374E7D;
    background: white;
    border: 1px solid #374E7D;
  }
`;