import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface SidebarContainerProps {
  isOpen: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  height: 100vh;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 55px;
  z-index: 9991;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${props => props.isOpen ? '250px' : '65px'};
  transition: width 0.3s ease-in-out;
`;

export const SidebarHeader = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  margin: 25px 0px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const UserInfoCollapsed = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const UserDetails = styled.div`
  margin-left: 10px;
`;

export const UserName = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #333;
  width: max-content;
`;

export const UserRole = styled.p`
  margin: 0;
  font-size: 12px;
  color: #6c757d;
  width: max-content;
`;

export const ToggleContainer = styled.div`
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ToggleButton = styled.button`
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0;
`;

export const SidebarMenu = styled.div`
  padding: 0;
  overflow-y: auto;
  flex-grow: 1;
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const MenuItem = styled.li`
  margin-bottom: 0;
  
  &.active a {
    color: #A5ADBA;
  }
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: #6c757d;
  text-decoration: none;
  transition: all 0.3s ease;
  border-bottom: 0.5px solid #DFE1E6;
  
  &:hover {
    background-color: #e9ecef;
    color: #343a40;
  }
`;

export const MenuIcon = styled.span`
  font-size: 20px !important;
  min-width: 24px;
  color: #6c757d;
`;

export const MenuItemWithSubmenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItemButton = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: #6c757d;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 0.5px solid #DFE1E6;
  width: 250px;
  &:hover {
    background-color: #e9ecef;
    color: #343a40;
  }
`;

export const MenuLabel = styled.span`
  flex-grow: 1;
  width: max-content;
`;

export const Submenu = styled.ul`
  list-style: none;
  padding-left: 20px;
  margin: 0;
`;

export const SubmenuItem = styled.li`
  margin-bottom: 0;
`;

export const SubmenuLink = styled(Link)`
  padding: 8px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #6c757d;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 250px;
  &:hover {
    background-color: #e9ecef;
    color: #343a40;
  }
`;