import styled from "../../shared/styled";
import { Anchor } from "../../components/src/ui/anchor/Anchor";

export const StyledLogo = styled(({ ...rest }) => <Anchor {...rest} />)`
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;

    img {
        width: 80%;
    }
`;
