import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Personnel } from '../types';
import CircularProgress from '@mui/material/CircularProgress';
import ModalPersonnelUpdate from '../../personnel/modal/ModalPersonnelUpdate';
import axios from 'axios';
import { SERVICEURL } from '../../../appconfig';
import { useHistory } from 'react-router-dom';

interface PersonnelTabProps {
  technoparkId: number;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  personnelFilters: any;
  setPersonnelFilters: (filters: any) => void;
  personnelPage: number;
  setPersonnelPage: (page: number) => void;
  personnelRowsPerPage: number;
  setPersonnelRowsPerPage: (rowsPerPage: number) => void;
}

const PersonnelTab: React.FC<PersonnelTabProps> = ({
  technoparkId,
  searchQuery,
  setSearchQuery,
  personnelFilters,
  setPersonnelFilters,
  personnelPage,
  setPersonnelPage,
  personnelRowsPerPage,
  setPersonnelRowsPerPage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] = useState<any>(null);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [personnelList, setPersonnelList] = useState<Personnel[]>([]);
  const mytoken = localStorage.getItem("token");
  const history = useHistory();

  const fetchPersonnelData = async () => {
    if (!technoparkId) return;
    
    setIsLoading(true);
    try {
      const response = await axios.post(
        SERVICEURL + "api/Technopark/GetTechnoparkPersonelInfoList",
        { TechnoparkId: technoparkId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + mytoken,
          },
        }
      );

      if (response.data) {
        const formattedData = response.data.map((item: any) => ({
          id: item.MemberId,
          sgkNo: item.SgkNo || '',
          projectName: item.ProjectName || '',
          technoparkName: item.TechnoparkName || '',
          technoparkShortName: item.TechnoparkShortName || '',
          workStartDate: item.WorkStartDate || '',
          workEndDate: item.WorkEndDate || '',
          name: item.MemberName || '',
          salary: item.Salary || '',
          startDate: item.StartDate || '',
          endDate: item.EndDate || '',
          projectStartDate: item.ProjectStartDate || '',
          projectEndDate: item.ProjectEndDate || '',
          position: item.ProjectTypeName || '',
          bilisimPersoneli: item.IsBilisimPersoneli || false,
          customer: item.CustomerName || '',
          title: item.Title || ''
        }));
        const uniquePersonnelList = mergeDuplicatePersonnel(formattedData);
        setPersonnelList(uniquePersonnelList);
      }
    } catch (error) {
      console.error("Personel verileri alınamadı:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // tekrarlayan verileri birleştirme
  const mergeDuplicatePersonnel = (personnelList: any[]) => {
    const personnelMap = new Map();

    personnelList.forEach(personnel => {
      const key = `${personnel.name}-${personnel.sgkNo}-${personnel.projectName}-${personnel.technoparkName}`;
      if (personnelMap.has(key)) {
        const existingPersonnel = personnelMap.get(key);
        existingPersonnel.startDate = personnel.startDate < existingPersonnel.startDate ? personnel.startDate : existingPersonnel.startDate;
        existingPersonnel.endDate = personnel.endDate > existingPersonnel.endDate ? personnel.endDate : existingPersonnel.endDate;
      } else {
        personnelMap.set(key, { ...personnel });
      }
    });

    return Array.from(personnelMap.values());
  };

  useEffect(() => {
    fetchPersonnelData();
  }, [technoparkId]);

  const uniqueTitles = Array.from(new Set(personnelList.map(p => p.title)));
  const uniquePositions = Array.from(new Set(personnelList.map(p => p.position)));
  const uniqueCustomer = Array.from(new Set(personnelList.map(p => p.customer)));

  const normalizeText = (text: string) => { 
    return text
      .toLocaleLowerCase("tr-TR")
      .replace(/ı/g, "i")
      .replace(/İ/g, "i");
  };
  // filtreleme
  const filteredData = personnelList.filter(p =>
    (searchQuery.length < 2 || normalizeText(p.name).includes(normalizeText(searchQuery))) &&
    (personnelFilters.title ? p.title === personnelFilters.title : true) &&
    (personnelFilters.position ? p.position === personnelFilters.position : true) &&
    (personnelFilters.customer ? p.customer === personnelFilters.customer : true) &&
    (personnelFilters.bilisim ? (personnelFilters.bilisim === 'yes' ? p.bilisimPersoneli : !p.bilisimPersoneli) : true)
  );

  const handleEdit = (personnel: any) => {
    setSelectedPersonnel(personnel);
    setCount(prev => prev + 1);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPersonnel(null);
  };

  const handleView = (personnel: any) => {
    if (personnel && personnel.id) {
      const memberId = personnel.id;
      console.log("Personel detay sayfasına yönlendiriliyor, MemberId:", memberId);
      history.push(`/personnel/${memberId}`);
    } else {
      console.error("Personel ID bulunamadı:", personnel);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          size="small"
          placeholder="Ara"
          sx={{ width: 200 }}
          InputProps={{
            style: { height: '40px' }
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel style={{ top: '-8px' }}>Ünvan</InputLabel>
          <Select
            size="small"
            label="Ünvan"
            value={personnelFilters.title}
            onChange={(e) => setPersonnelFilters({ ...personnelFilters, title: e.target.value })}
            sx={{minWidth: 200, height: "40px" }}
          >
            <MenuItem value="">Tümü</MenuItem>
            {uniqueTitles.map((title) => (
              <MenuItem key={title} value={title}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel style={{ top: '-8px' }}>P.T.</InputLabel>
          <Select
            size="small"
            label="P.T."
            value={personnelFilters.position}
            onChange={(e) => setPersonnelFilters({ ...personnelFilters, position: e.target.value })}
            sx={{minWidth: 200, height: "40px" }}
          >
            <MenuItem value="">Tümü</MenuItem>
            {uniquePositions.map((position) => (
              <MenuItem key={position} value={position}>
                {position}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel style={{ top: '-8px' }}>Bilişim</InputLabel>
          <Select
            size="small"
            label="Bilişim"
            value={personnelFilters.bilisim}
            onChange={(e) => setPersonnelFilters({ ...personnelFilters, bilisim: e.target.value })}
            sx={{minWidth: 200, height: "40px" }}
          >
            <MenuItem value="">Tümü</MenuItem>
            <MenuItem value="yes">Evet</MenuItem>
            <MenuItem value="no">Hayır</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel style={{ top: '-8px' }}>Müşteri</InputLabel>
          <Select
            size="small"
            label="Müşteri"
            value={personnelFilters.customer}
            onChange={(e) => setPersonnelFilters({ ...personnelFilters, customer: e.target.value })}
            sx={{minWidth: 200, height: "40px" }}
          >
            <MenuItem value="">Tümü</MenuItem>
            {uniqueCustomer.map((customer) => (
              <MenuItem key={customer} value={customer}>
                {customer}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sıra No</TableCell>
                  <TableCell>SGK Girişi</TableCell>
                  <TableCell>Proje Adı</TableCell>
                  <TableCell>Personel Adı</TableCell>
                  <TableCell>Maaş</TableCell>
                  <TableCell>İşe Giriş - Çıkış Tarihi</TableCell>
                  <TableCell>Proje Giriş - Çıkış Tarihi</TableCell>
                  <TableCell>P.T.</TableCell>
                  <TableCell>Bilişim Personeli</TableCell>
                  <TableCell>Müşteri</TableCell>
                  <TableCell>Ünvan</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(personnelPage * personnelRowsPerPage, personnelPage * personnelRowsPerPage + personnelRowsPerPage)
                  .map((personnel, index) => (
                    <TableRow key={personnel.id}>
                      <TableCell>{index + 1 + (personnelPage * personnelRowsPerPage)}</TableCell>
                      <TableCell>{personnel.sgkNo}</TableCell>
                      <TableCell>{personnel.projectName}</TableCell>
                      <TableCell>{personnel.name}</TableCell>
                      <TableCell>{personnel.salary}</TableCell>
                      <TableCell>{`${personnel.startDate} - ${personnel.endDate}`}</TableCell>
                      <TableCell>{`${personnel.projectStartDate} - ${personnel.projectEndDate}`}</TableCell>
                      <TableCell>{personnel.position}</TableCell>
                      <TableCell>{personnel.bilisimPersoneli ? 'Evet' : 'Hayır'}</TableCell>
                      <TableCell>{personnel.customer}</TableCell>
                      <TableCell>{personnel.title}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton 
                            size="small"
                            onClick={() => handleEdit(personnel)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            size="small"
                            onClick={() => handleView(personnel)}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            page={personnelPage}
            onPageChange={(event, newPage) => setPersonnelPage(newPage)}
            rowsPerPage={personnelRowsPerPage}
            onRowsPerPageChange={(event) => {
              setPersonnelRowsPerPage(parseInt(event.target.value, 10));
              setPersonnelPage(0);
            }}
            labelRowsPerPage="Sayfa başına satır"
          />
        </>
      )}

      {selectedPersonnel && (
        <ModalPersonnelUpdate
          show={showModal}
          onClose={handleCloseModal}
          personelName={selectedPersonnel.name}
          personelId={selectedPersonnel.id}
          technoparkId={selectedPersonnel.technoparkId}
          technoparkName={selectedPersonnel.technoparkName}
          projectTypeId={selectedPersonnel.projectTypeId}
          count={count}
        />
      )}
    </Box>
  );
};

export default PersonnelTab;
