import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import './App.css';
import Preloader from './layouts/preloader/Preloader';
import NotFound from './pages/notFound/NotFound';
import Sidebar from './components/src/Sidebar/Sidebar';
import { logoutAllTabs } from './components/src/header/profile-dropdown';
import TeknoparkDetail from './pages/teknopark/TeknoparkDetail';
import ProjectDetail from './pages/project/ProjectDetail';
import Nav from './layouts/nav/Nav';
const Login = lazy(() => import("./pages/login/Login"));
const Home = lazy(() => import("./pages/home"));
const Teknopark = lazy(() => import("./pages/teknopark/Teknopark"))
const TeknoparkRapor = lazy(() => import("./pages/teknoparkRapor/TeknoparkRapor"))
const Project = lazy(() => import("./pages/project/Project"))
const PersonnelHome = lazy(() => import("./pages/HomePersonnel/Personnel"))
const TeknoparkAddNotCustomer = lazy(() => import("./pages/teknoparkAddNotCustomer/TeknoparkAddNotCustomer"));
const PersonnelDetail = lazy(() => import("./pages/personnel/PersonnelDetail"))
const ProjectClosingReport = lazy(() => import("./pages/ProjectClosingReport/ProjectClosingReport"))
const ProjectPersonnelReport = lazy(() => import("./pages/projectPersonnelReport/ProjectPersonnelReport"));
const App: React.FC = () => {

  useEffect(() => {
    logoutAllTabs()
  }, [])
  const token = localStorage.getItem('token')
  const tokenDuration = localStorage.getItem('tokenDuration')

  if (token == null) {
    logoutAllTabs()
  }
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  useEffect(() => {
    if (token != null) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
    }
  }, [token]);

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/404" component={NotFound} />
          <Route>
            <>
              {isLoggedIn && <Sidebar />}
              {isLoggedIn && <Nav />}
              <Suspense fallback={<Preloader />}>
                <Switch >
                  <Route exact path="/" component={() => isLoggedIn ? <Home /> : <Redirect to='/login' />} />
                  <Route exact path="/teknoparkAddNotCustomer" component={() => isLoggedIn ? <TeknoparkAddNotCustomer /> : <Redirect to='/login' />} />
                  <Route exact path="/teknopark" component={() => isLoggedIn ? <Teknopark /> : <Redirect to='/login' />} />
                  <Route exact path="/technoparkReport" component={() => isLoggedIn ? <TeknoparkRapor /> : <Redirect to='/login' />} />
                  <Route exact path="/project" component={() => isLoggedIn ? <Project /> : <Redirect to='/login' />} />
                  <Route exact path="/projectClosingReport" component={() => isLoggedIn ? <ProjectClosingReport /> : <Redirect to='/login' />} />
                  <Route exact path="/personnel" component={() => isLoggedIn ? <PersonnelHome /> : <Redirect to='/login' />} />
                  <Route exact path="/login" component={() => isLoggedIn == false ? <Login /> : <Redirect to='/' />} />
                  <Route path="/teknopark/detail/:id" component={TeknoparkDetail} />
                  <Route path="/project/detail/:id" component={ProjectDetail} />
                  <Route path="/personnel/:memberId" component={PersonnelDetail} />
                  <Route exact path="/projectPersonnelReport" component={() => isLoggedIn ? <ProjectPersonnelReport /> : <Redirect to='/login' />} />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </>
          </Route>
        </Switch>
      </Router>
    </div>

  );
}

export default App;


