import styled, { themeGet, css, device } from "../../shared/styled";
import { Table } from "../../components";
import { Box, TableCell } from "@mui/material";

export const StyledTable = styled(({ ...rest }) => <Table {...rest} />)``;

export const StyledTheadTR = styled.tr`
    &:first-child {
        th {
            border-top-width: 0;
            font-size: 16px;
            background-color: #f5f6fa;
            text-align: center;
            border: 1px solid #a8a8a8;
            ${(props) =>
                props.theme.name === "dark" &&
                css`
                    background-color: rgba(255, 255, 255, 0.05);
                `}
        }
    }
    th:first-child {
        border-left-width: 0;
        text-align: center;
    }
    th:last-child {
        border-right-width: 0;
    }
    &:last-child {
        th {
            font-size: 14px;
            letter-spacing: 0,8px;
            padding: 10px 16px;
            color: #000;
            background-color: #fdd;
            font-weight: 500;
            &:not(:first-child) {
                text-align: center;
            }
        }
    }
`;

export const StyledTD = styled.td`
    border-color: ${themeGet("colors.border")};
    padding: 10px 15px !important;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    &:first-child {
        text-align: center;
    }
    strong {
        font-weight: 500;
        font-family: ${themeGet("fonts.interUi")};
    }
`;

export const StyledFormControl = styled.div`
  position: absolute;
  width: 92%;
  color: black;
  & label.Mui-focused {
    color: #3b8c8c;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #3b8c8c;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: black;
    }
    &:hover fieldset {
      border-color: orange;
    }
    &.Mui-focused fieldset {
      border-color: #3b8c8c;
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  width: 92%;
  color: black;
  height: 98%;
  border-radius: 4px;
  border-color: #AEADAD;
  font-size: 16px;
`;

export const StyledInput2 = styled.input`
  position: relative;
  width: 100%;
  color: black;
  height: 2.5rem;
  border-radius: 4px;
  border-color: #AEADAD;
  font-size: 16px;
`;

export const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  color: #959393;
  font-size: 14px;
`;

export const StyledButton = styled.button`
  font-weight: bold;
  margin: 7px;
`;

export const StyledSaveButton = styled.button`
  position: relative;
  float: right;
  margin-right: 10px;
  font-weight: bold;
  margin-top: 0px;
  width: auto;
  height: 2.8rem;
  border-radius: 4px;
  border-color: #AEADAD;
  font-size: 14px;
  right: 9px;
`;

export const StyledButtonIcon = styled.span`
  margin-right: 5px;
`;

export const StyledButtonIconDelete = styled(StyledButtonIcon)`
  color: danger;
`;

export const StyledTextField = styled.div`
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: rgb(26 108 225 / 10%) 0px 0px 25px;
  padding: 15px;
  height: 100%;
`;

export const ColorBackground = styled.div`
  background-color: rgb(247,250,255);
`;
export const WhiteBackground = styled.div`
  background-color: white;
`;
export const StyledHeader = styled.div`
  margin-left: 117px;
  height: 40px;
`;

export const ScrollContainer = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const HeaderList = styled.div`
  background-color: #f7f7f7;
  border: 1px solid #afafaf;
  border-radius: 1px;
`;

export const Statement = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #afafaf;
  border-radius: 1px;
`;

export const StyledStatCard = styled(Box)`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  position: relative;
`;

export const StyledIconWrapper = styled(Box)`
  background-color: #F8F9FC;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const StyledTableCell = styled(TableCell)`
  color: #666;
  font-weight: 500;
`;

export const StyledStatusChip = styled(Box)<{ color: string; bgColor: string }>`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
`;

export const Container = styled.div`
  margin: 80px 25px 80px 80px;
      ${device.large} {
        margin: 50px 110px 5px;
    }
`;
export const BgContainer = styled.div`
    background: #F4F5F7;
    border-radius: 12px;
    padding: 27px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`;
