import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BgGrid, BgGridInner } from './style';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Breadcrumbs,
  Link,
  Button,
  Avatar,
  IconButton
} from '@mui/material';
import Content from "../../layouts/content";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ModalUpdate from './modal/ModalUpdate';
import PersonnelTab from '../teknopark/teknoparkTabs/PersonnelTab';

interface LocationState {
  projectData: {
    ProjectId: number;
    Name: string;
    ProjectNo: number;
    ProjectStartDate: string;
    ProjectEndDate: string;
    ProjectEstEndDate: string;
    ProjectStatusId: number;
    ProjectStatusName: string;
    TotalCapacity: number;
    ArgeCapacity: number;
    SupportCapacity: number;
    IsAdditionalTime: boolean;
    TechnoparkId: number;
    TechnoparkName: string;
    RelatedMemberName: string;
    EmployeeCount: number;
    ExtraTime?: number;
    ITCount: number;
  };
}

const ProjectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<LocationState>();
  const projectData = location.state?.projectData;
  console.log(projectData.TechnoparkId);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showPersonnel, setShowPersonnel] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [personnelFilters, setPersonnelFilters] = useState({
    title: '',
    position: '',
    customer: '',
    bilisim: ''
  });
  const [personnelPage, setPersonnelPage] = useState(0);
  const [personnelRowsPerPage, setPersonnelRowsPerPage] = useState(10);

  if (!projectData) {
    return <div>Proje bulunamadı</div>;
  }

  const handleCloseModal = () => {
    setShowUpdateModal(false);
  };

  return (
    <>
      <div style={{ minHeight: '100vh' }}>
        <Content>
          <Container>
            {/* Breadcrumb */}
            <Box sx={{ mb: 3, mt: 6 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Ana Sayfa
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href="/teknopark"
                >
                  Teknoparklar
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href={`/teknopark/detail/${projectData?.TechnoparkId}`}
                >
                  {projectData?.TechnoparkName}
                </Link>
                <Typography color="text.primary">
                  {projectData?.Name}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {projectData?.Name}
            </Typography>
            
            {/* Status Bar */}
            <BgGrid>
              <Grid container alignItems="center" justifyContent="space-between" spacing={3} style={{padding: '20px'}}>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Statü
                  </Typography>
                  <Chip
                    label={projectData?.ProjectStatusName}
                    size="small"
                    sx={{ backgroundColor: '#e8f5e9', color: projectData?.ProjectStatusId === 1 ? "#006644" : "default" }}
                  />
                </Grid>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Proje Başlangıç Tarihi
                  </Typography>
                  <Typography variant="body1">
                    {projectData?.ProjectStartDate}
                  </Typography>
                </Grid>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Proje Bitiş Tarihi
                  </Typography>
                  <Typography variant="body1">
                    {projectData?.ProjectEndDate}
                  </Typography>
                </Grid>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Toplam Kapasite
                  </Typography>
                  <Typography variant="body1">
                    {projectData?.TotalCapacity}
                  </Typography>
                </Grid>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Personel Sayısı
                  </Typography>
                  <Typography variant="body1">
                    {projectData?.EmployeeCount}
                  </Typography>
                </Grid>
                <Grid item sx={{ 
                  borderRight: '1px solid #e0e0e0',
                  pr: 3
                }}
                style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Ek Süre
                  </Typography>
                  <Typography variant="body1">
                    {projectData?.ExtraTime} Gün
                  </Typography>
                </Grid>
                <Grid item style={{paddingTop: '0px'}}>
                  <Typography variant="body2" color="text.secondary">
                    Atanan Kişi
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar sx={{ width: 24, height: 24 }}>
                      {projectData?.RelatedMemberName?.charAt(0)}
                    </Avatar>
                    <Typography>{projectData?.RelatedMemberName}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </BgGrid>
            <br />
            {/* Add PersonnelTab conditionally */}
           
            {/* Personnel Cards */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6} md={3}>
                <BgGridInner>
                  <Avatar sx={{ bgcolor: '#e3f2fd', color: '#2196f3' }}>
                    <PersonIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Bilişim Personeli
                    </Typography>
                    <Typography variant="h5">
                     {projectData?.ITCount}
                    </Typography>
                  </Box>
                </BgGridInner>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BgGridInner>
                  <Avatar sx={{ bgcolor: '#f3e5f5', color: '#9c27b0' }}>
                    <GroupIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Ar-Ge Personeli
                    </Typography>
                    <Typography variant="h5">
                      {projectData?.ArgeCapacity}
                    </Typography>
                  </Box>
                </BgGridInner>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BgGridInner>
                  <Avatar sx={{ bgcolor: '#fff3e0', color: '#ff9800' }}>
                    <GroupIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Destek Personeli
                    </Typography>
                    <Typography variant="h5">
                      {projectData?.SupportCapacity}
                    </Typography>
                  </Box>
                </BgGridInner>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BgGridInner>

                  <Box>
                    <Typography variant="subtitle2" fontSize={'12px'} color="text.secondary">
                      Projedeki Dökümanlar
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,


                    }}>
                      <DescriptionIcon color="action" style={{ backgroundColor: 'white', fontSize: '20px', padding: '5px', borderRadius: '30%' }} />
                      <Box sx={{ flex: 1 }}>
                        <Typography style={{ fontSize: '11px' }}>Project_requirements.pdf</Typography>
                        <Typography variant="caption" style={{ fontSize: '10px' }} color="text.secondary">
                          5.6 MB
                        </Typography>
                      </Box>
                      <IconButton>
                        <DownloadIcon style={{ fontSize: '20px', backgroundColor: '#80808073', borderRadius: '30%', padding: '3px' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </BgGridInner>
              </Grid>


            </Grid>
            <br />
             <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setShowPersonnel(!showPersonnel)}
                startIcon={<PersonIcon />}
                sx={{ color: '#374E7D', borderColor: '#374E7D' ,textTransform: 'none'}}
              >
                {showPersonnel ? 'Çalışanları Gizle' : 'Çalışanları Görüntüle'}
              </Button>
            </Box>
            <br/>
             {showPersonnel && (
              <Box sx={{ mt: 3 }}>
                <PersonnelTab 
                  technoparkId={projectData.TechnoparkId}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  personnelFilters={personnelFilters}
                  setPersonnelFilters={setPersonnelFilters}
                  personnelPage={personnelPage}
                  setPersonnelPage={setPersonnelPage}
                  personnelRowsPerPage={personnelRowsPerPage}
                  setPersonnelRowsPerPage={setPersonnelRowsPerPage}
                />
              </Box>
            )}
          </Container>
        </Content>
      </div>
      <ModalUpdate
        show={showUpdateModal}
        onClose={handleCloseModal}
        projectId={projectData.ProjectId}
        name={projectData.Name}
        nameId={projectData.ProjectId}
        projectNo={projectData.ProjectNo}
        technoparkId={projectData.TechnoparkId}
        technoparkName={projectData.TechnoparkName}
        relatedMemberId={0}
        relatedMemberName={projectData.RelatedMemberName}
        argeCapacity={projectData.ArgeCapacity}
        supportCapacity={projectData.SupportCapacity}
        projectStartDate={projectData.ProjectStartDate}
        projectEndDate={projectData.ProjectEndDate}
        projectEstEndDate={projectData.ProjectEstEndDate}
        projectStatusId={projectData.ProjectStatusId}
        projectStatusName={projectData.ProjectStatusName}
        isAdditionalTime={projectData.IsAdditionalTime}
        count={0}
        getAllProject={() => { }}
        getCapacity={() => { }}
      />
    </>
  );
};

export default ProjectDetail; 