import { FC, useState, useReducer, useEffect} from "react";
import { X } from "react-feather";
import {
  Modal,
  ModalTitle,
  ModalClose,
  ModalFooter,
} from "../../../../src/components/src/ui/modal-three/modalthree";
import { Col, Row, Button, Card } from "../../../components";
import { TextField } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { SERVICEURL } from "../../../appconfig";
import axios, { AxiosError } from 'axios';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
// import { getCompaniesList, getCostCenterList, getCurrentDebtList, getMainCostCenterGroupList, getSubCostCenterGroupList } from "../../../../../redux/actions/memberAction";
import { getAllTeknoparkList } from "../../../redux/actions/memberAction";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import React from "react";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import { ModalBody, ModalHeader } from "../../../../src/components/src/ui/modal/modal";
import { getTechnoparkNameList, getRelatedMemberNameList } from "../../../redux/actions/memberAction";
import CurrencyInput from "react-currency-input-field";
import { useCommonStyles } from '../../../styles/commonStyles';

interface IProps {
  show: boolean;
  technoparkId: any;
  name: any;
  shortName: any;
  relatedMemberId: any;
  relatedMemberName: any;
  area: any;
  totalCapacity: any;
  rent: any;
  rentStartDate: any;
  rentFinishDate: any;
  dues: any;
  duesStartDate: any;
  duesFinishDate: any;
  address: any;
  statement: any;
  isActive: boolean;
  onClose: () => void;
  getAllTeknopark: () => void;
}

const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      position: 'absolute',
      width: '92%',
      color: 'black',
      height: '98%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px'
    },
    formControl2: {
      position: 'relative',
      width: '100%',
      color: 'black',
      height: '230%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px'
    },
    formControlTop: {
      position: 'absolute',
      width: '92%',
      color: 'black',
      height: '98%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px',
      marginTop: '20px'
    },
    inputControl2: {
      position: "relative",
      width: "100%",
      color: "black",
      height: "54px",
      borderRadius: "4px",
      borderColor: "#AEADAD",
      fontSize: "16px",
    },
    labelControl: {
      position: "relative",
      width: "100%",
      color: "#959393",
      //height: '3.2 rem',

      fontSize: "12px",
    },
  })
);
const ModalUpdateTeknopark: FC<IProps> = ({ show, onClose, technoparkId, name, shortName, relatedMemberId, relatedMemberName, area, totalCapacity, rent, rentStartDate, rentFinishDate, dues, duesStartDate, duesFinishDate, address, statement, isActive,getAllTeknopark }) => {
  const dis = useDispatch();
  type State = {
    technoparkId: number;
    name: string;
    shortName: string;
    relatedMemberId: number;
    area: string;
    totalCapacity: number | null;
    rent: string;
    rentStartDate: Date | null;
    rentFinishDate: Date | null;
    dues: string;
    duesStartDate: Date | null;
    duesFinishDate: Date | null;
    address: string;
    statement: string;
    isActive: boolean | null;
  }
  const initialState: State = {
    technoparkId: 0,
    name: '',
    shortName: '',
    relatedMemberId: 0,
    area: '',
    totalCapacity: null,
    rent: '',
    rentStartDate: null,
    rentFinishDate: null,
    dues: '',
    duesStartDate: null,
    duesFinishDate: null,
    address: '',
    statement: '',
    isActive: null
  }
  type Action =
    | { type: 'setTechnoparkId'; payload: number }
    | { type: 'setName'; payload: string }
    | { type: 'setShortName'; payload: string }
    | { type: 'setRelatedMemberId'; payload: number }
    | { type: 'setArea'; payload: string }
    | { type: 'setTotalCapacity'; payload: number | null }
    | { type: 'setRent'; payload: string }
    | { type: 'setRentStartDate'; payload: Date }
    | { type: 'setRentFinishDate'; payload: Date }
    | { type: 'setDues'; payload: string }
    | { type: 'setDuesStartDate'; payload: Date }
    | { type: 'setDuesFinishDate'; payload: Date }
    | { type: 'setAddress'; payload: string }
    | { type: 'setStatement'; payload: string }
    | { type: 'setIsActive'; payload: boolean }

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'setTechnoparkId':
        return {
          ...state,
          technoparkId: action.payload,
        };
      case 'setName':
        return {
          ...state,
          name: action.payload,
        };
      case 'setShortName':
        return {
          ...state,
          shortName: action.payload,
        };
      case 'setRelatedMemberId':
        return {
          ...state,
          relatedMemberId: action.payload,
        };
      case 'setArea':
        return {
          ...state,
          area: action.payload,
        };
      case 'setTotalCapacity':
        return {
          ...state,
          totalCapacity: action.payload,
        };

      case 'setRent':
        return {
          ...state,
          rent: action.payload,
        };

      case 'setRentStartDate':
        return {
          ...state,
          rentStartDate: action.payload,
        };


      case 'setRentFinishDate':
        return {
          ...state,
          rentFinishDate: action.payload,
        };


      case 'setDues':
        return {
          ...state,
          dues: action.payload,
        };

      case 'setDuesStartDate':
        return {
          ...state,
          duesStartDate: action.payload,
        };

      case 'setDuesFinishDate':
        return {
          ...state,
          duesFinishDate: action.payload,
        };
      case 'setAddress':
        return {
          ...state,
          address: action.payload,
        };
      case 'setStatement':
        return {
          ...state,
          statement: action.payload,
        };
      case 'setIsActive':
        return {
          ...state,
          isActive: action.payload
        };
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const [statusOptionName, setStatusOptionName] = useState("");

  const statusOptions = [
    { id: 1, name: 'Aktif' },
    { id: 0, name: 'Pasif' }
  ];

  const [relatedMemberValue, setRelatedMemberValue] = React.useState<any | null>();
  const [relatedMemberOptionName, setRelatedMemberOptionName] = useState("");
  const [relatedMemberInputName, setRelatedMemberInputName] = useState("");
  const { relatedMemberNameList } = useSelector((state: RootStateOrAny) => state.relatedMemberNameList);

  function getRelatedMemberName() {
    dis(getRelatedMemberNameList());
  }
  const commonStyles = useCommonStyles();

  useEffect(() => {
    getRelatedMemberName();
  }, []);

  useEffect(() => {
    state.technoparkId = technoparkId;
    state.name = name;
    state.shortName = shortName;
    state.address = address;
    state.relatedMemberId = relatedMemberId;
    state.area = area;
    state.isActive = isActive;
    state.statement = statement;
    state.dues = dues;
    state.rent = rent;
    state.totalCapacity = totalCapacity;
    setRelatedMemberValue(relatedMemberId);
    setRelatedMemberInputName(relatedMemberName);
    setRelatedMemberOptionName(relatedMemberName);
    dispatch({
      type: 'setIsActive',
      payload: isActive
    });

    if (duesStartDate != "") {
      var dd = duesStartDate?.substring(0, 2)
      var mm = duesStartDate?.substring(3, 5)
      var yyyy = duesStartDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '5:30:00';
      setStartDuesMonthYear(new Date(datee))
    }
    if (rentStartDate != "") {
      var dd = rentStartDate?.substring(0, 2)
      var mm = rentStartDate?.substring(3, 5)
      var yyyy = rentStartDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '5:30:00';
      setStartRentMonthYear(new Date(datee))
    }
    if (duesFinishDate != "") {
      var dd = duesFinishDate?.substring(0, 2)
      var mm = duesFinishDate?.substring(3, 5)
      var yyyy = duesFinishDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEndDuesMonthYear(new Date(datee))
    }
    if (rentFinishDate != "") {
      var dd = rentFinishDate?.substring(0, 2)
      var mm = rentFinishDate?.substring(3, 5)
      var yyyy = rentFinishDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEndRentMonthYear(new Date(datee))
    }


  }, [technoparkId, name, shortName, address, relatedMemberId, area, isActive, statement, dues, rent, totalCapacity, duesFinishDate, rentFinishDate]);

  const handleSave = () => {

    const mytoken = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + mytoken,
      },
    };
    const input = {
      TechnoparkId: state.technoparkId,
      Name: state.name,
      ShortName: state.shortName,
      RelatedMemberId: relatedMemberValue,
      Area: state.area,
      TotalCapacity: state.totalCapacity,
      Rent: String(state.rent),
      RentStartDate: startRentMonthYear,
      RentFinishDate: endRentMonthYear,
      Dues: String(state.dues),
      DuesStartDate: startDuesMonthYear,
      DuesFinishDate: endDuesMonthYear,
      Address: state.address,
      Statement: state.statement,
      IsActive: state.isActive
    };
    axios
      .post(SERVICEURL + 'api/Technopark/UpdateTechnoparkInfo?', input, config)
      .then((response) => {
        if (response.data.IsOk) {
          setOpen(true);
          getAllTeknopark();
        }
        else {
          console.log(response.data.Message)
        }

      })
      .catch((error: AxiosError) => { console.log(error.message); });


  }
  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
    cancelForm();
  };

  const cancelForm = () => {
    dispatch({
      type: 'setName',
      payload: name
    });
    dispatch({
      type: 'setShortName',
      payload: shortName
    });
    dispatch({
      type: 'setRelatedMemberId',
      payload: relatedMemberId
    });
    dispatch({
      type: 'setArea',
      payload: area
    });
    dispatch({
      type: 'setTotalCapacity',
      payload: totalCapacity
    });
    dispatch({
      type: 'setRent',
      payload: rent
    });
    dispatch({
      type: 'setDues',
      payload: dues
    });
    dispatch({
      type: 'setAddress',
      payload: address
    });
    dispatch({
      type: 'setStatement',
      payload: statement
    });
    setRelatedMemberValue(relatedMemberId);
    setRelatedMemberInputName(relatedMemberName);
    setRelatedMemberOptionName(relatedMemberName);
    dispatch({
      type: 'setIsActive',
      payload: isActive
    });

    if (duesStartDate != "") {
      var dd = duesStartDate?.substring(0, 2)
      var mm = duesStartDate?.substring(3, 5)
      var yyyy = duesStartDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '5:30:00';
      setStartDuesMonthYear(new Date(datee))
    }
    if (rentStartDate != "") {
      var dd = rentStartDate?.substring(0, 2)
      var mm = rentStartDate?.substring(3, 5)
      var yyyy = rentStartDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '5:30:00';
      setStartRentMonthYear(new Date(datee))
    }
    if (duesFinishDate != "") {
      var dd = duesFinishDate?.substring(0, 2)
      var mm = duesFinishDate?.substring(3, 5)
      var yyyy = duesFinishDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEndDuesMonthYear(new Date(datee))
    }
    if (rentFinishDate != "") {
      var dd = rentFinishDate?.substring(0, 2)
      var mm = rentFinishDate?.substring(3, 5)
      var yyyy = rentFinishDate?.substring(6, 10)
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEndRentMonthYear(new Date(datee))
    }
  }

  const handleDeleteModal = () => {
    cancelForm();
  };

  const OnCloseandDelete = () => {
    onClose();
    cancelForm();
  }

  const handleChangeRelatedMember = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {

      setRelatedMemberValue(newValue.id);
      dispatch({
        type: "setRelatedMemberId",
        payload: newValue.id,
      });
    }
    if (newValue == null) {
      setRelatedMemberValue(newValue);
      newValue = 0;
      dispatch({
        type: "setRelatedMemberId",
        payload: newValue,
      });
    }
  };
  const handleChangeRelatedMemberInput = (
    event: any,
    newValueInput: any | null
  ) => {
    if (newValueInput != null && newValueInput != undefined) {
      setRelatedMemberOptionName(newValueInput);
      setRelatedMemberInputName(newValueInput);
    }
    if (newValueInput == null) {
      setRelatedMemberInputName(newValueInput);
    }
  };
  const handleChangeTechnoparkName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    dispatch({
      type: 'setName',
      payload: event.target.value,
    });
  };
  const handleChangeShortName = (
    event: any
  ) => {
    dispatch({
      type: 'setShortName',
      payload: event.target.value,
    });
  };
  const handleChangeArea: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setArea',
      payload: event.target.value,
    });
  };
  const handleChangeTotalCapacity: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setTotalCapacity',
      payload: event.target.value,
    });
  };
  const handleChangeRent = (value: any) => {
    dispatch({
      type: 'setRent',
      payload: value,
    });
  };
  const handleChangeDues = (value: any) => {
    dispatch({
      type: 'setDues',
      payload: value,
    });
  };
  const handleChangeAdress: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setAddress',
      payload: event.target.value,
    });
  };

  const handleChangeStatement: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setStatement',
      payload: event.target.value,
    });
  };

  const handleChangeIsActive = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      dispatch({
        type: "setIsActive",
        payload: newValue.props.value,
      });
    }
  };

  const [startRentMonthYear, setStartRentMonthYear] = useState<Date | null>(null);
  const [endRentMonthYear, setEndRentMonthYear] = useState<Date | null>(null);
  const [startDuesMonthYear, setStartDuesMonthYear] = useState<Date | null>(null);
  const [endDuesMonthYear, setEndDuesMonthYear] = useState<Date | null>(null);
  const [open, setOpen] = React.useState(false);

  //Saat farkından dolayı backende 1 gün eksik düşüyor,bu nedenle saat eklendi.
  startRentMonthYear?.setHours(5, 30, 0, 0);
  endRentMonthYear?.setHours(23, 0, 0, 0);
  startDuesMonthYear?.setHours(5, 30, 0, 0);
  endDuesMonthYear?.setHours(23, 0, 0, 0);


  const classesSelect = useStylesSelect();

  const handleModalClick = () => {
    // Modal içine tıklandığında yapılacak işlemler
  };
  return (
    <>
      <Modal show={show} onClose={handleModalClick}>
        <form>
          <ModalHeader>
            <ModalTitle>Teknopark Kayıt Düzenleme</ModalTitle>
            <ModalClose onClose={OnCloseandDelete}>
              <X />
            </ModalClose>
          </ModalHeader>

          <ModalBody>
            <Row mb={3}>
              <Col lg={3} md sm={12} xs={11}>

                {Object.values(relatedMemberNameList).length > 0 && (
                  <Autocomplete
                    options={Object.values(relatedMemberNameList.data.PersonnelList).map((item: any) => item)}
                    getOptionLabel={(options) => options.name == undefined ? relatedMemberOptionName : options.name}
                    value={relatedMemberValue == null ? 0 : relatedMemberValue}
                    onChange={handleChangeRelatedMember}
                    inputValue={relatedMemberInputName}
                    onInputChange={handleChangeRelatedMemberInput}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%"}}
                        variant="outlined"
                        {...params}
                        label="Yetkili Kişi"
                      />
                    )}
                  />
                )}

              </Col >
              <Col lg={3} md sm={12} xs={11} >
                <Autocomplete
                  autoHighlight
                  options={statusOptions}
                  getOptionLabel={(options) => options.name == undefined ? statusOptionName : options.name}
                  value={state.isActive ? statusOptions[0] : statusOptions[1]}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: "setIsActive",
                        payload: newValue.id === 1,
                      });
                    }
                  }}
                  disablePortal={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Proje Durumu"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Teknopark Adı"
                  variant="outlined"
                  value={state.name}
                  onChange={handleChangeTechnoparkName}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11} >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="T. Kısaltılmış Ad"
                  variant="outlined"
                  value={state.shortName}
                  onChange={handleChangeShortName}
                />
              </Col>
            </Row>

            <Row mb={4}>
              <Col lg={3} md sm={6} xs={6}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Kira Başlangıç Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startRentMonthYear}
                  onChange={(date: Date | null) => setStartRentMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
              <Col lg={3} md sm={6} xs={6}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Aidat Başlangıç Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startDuesMonthYear}
                  onChange={(date: Date | null) => setStartDuesMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""

                />
              </Col>
              <Col lg={3} md sm={6} xs={6}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Kira Bitiş Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={endRentMonthYear}
                  onChange={(date: Date | null) => setEndRentMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
              <Col lg={3} md sm={6} xs={6}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Aidat Bitiş Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={endDuesMonthYear}
                  onChange={(date: Date | null) => setEndDuesMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
            </Row>
            <Row mb={4}>
              <Col lg={3} md sm={12} xs={11} >
                <TextField
                  fullWidth
                  label="Alan"
                  variant="outlined"
                  value={state.area}
                  onChange={handleChangeArea}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ zIndex:0 ,top:"5px"}}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11} mt={-3}>
                <label className={classesSelect.labelControl}>m2 başına kira</label>

                <CurrencyInput
                  className={classesSelect.inputControl2}
                  id="input-example"
                  name="input-name"
                  prefix=""
                  value={state.rent}
                  groupSeparator="."
                  decimalSeparator=","
                  onValueChange={handleChangeRent}
                />
                {/* <TextField
                  fullWidth
                  label="m^2 kira"
                  variant="outlined"
                  value={state.rent}
                  onChange={handleChangeRent}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}
              </Col>
              <Col lg={3} md sm={12} xs={11} mt={-3}>
                <label className={classesSelect.labelControl}>m2 başına aidat</label>

                <CurrencyInput
                  className={classesSelect.inputControl2}
                  id="input-example"
                  name="input-name"
                  prefix=""
                  value={state.dues}
                  groupSeparator="."
                  decimalSeparator=","
                  onValueChange={handleChangeDues}
                />
                {/* <TextField
                  fullWidth
                  label="m^2 aidat"
                  variant="outlined"
                  value={state.dues}
                  onChange={handleChangeDues}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}
              </Col>
              <Col lg={3} md sm={12} xs={11}>
                <TextField
                  fullWidth
                  label="Toplam Kapasite"
                  variant="outlined"
                  value={state.totalCapacity}
                  onChange={handleChangeTotalCapacity}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ zIndex:0 ,top:"5px"}}
                />
              </Col>
            </Row>

            <Row mb={3}>
              <Col lg md sm={11} xs={11}>
                <TextField
                  fullWidth
                  label="Adres"
                  variant="outlined"
                  value={state.address}
                  onChange={handleChangeAdress}
                  style={{ zIndex:0 }}
                />
              </Col>
            </Row>
            
            <Row mb={1}>
              <Col lg md sm={11} xs={11}>
                <TextField
                  variant="outlined"
                  label="Açıklama"
                  value={state.statement}
                  onChange={handleChangeStatement}
                  style={{ width: "100%", marginTop: "10px",zIndex:0}}
                  InputProps={{
                    style: {
                      height: "60px"
                    }
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              className={commonStyles.clearButton}
              type="reset"
              onClick={() => handleDeleteModal()} >
              Temizle
            </Button>
            <Button
              className={commonStyles.saveButton}
              onClick={handleSave}>
              Kaydet
            </Button>
            <Snackbar open={open} autoHideDuration={2000}
              onClose={handleClose}>
              <Alert onClose={() => handleClose} severity="success" sx={{ width: '200%' }}>
                Başarıyla kaydedildi.
              </Alert>
            </Snackbar>

          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

export default ModalUpdateTeknopark;