import { FC } from "react";
import { ThemeProvider, themes } from "./shared/styled";
import { GlobalStyle } from "./shared/css";
import { TTheme } from "./shared/types";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { toggleTheme } from "./redux/slices/ui";


const Theme: FC = ({ children }) => {
    const dispatch = useAppDispatch();
    const { theme } = useAppSelector((state) => state.ui);

    const themeHandler = (curTheme: TTheme) => {
        dispatch(toggleTheme({ theme: curTheme }));
    };
    return (
        <ThemeProvider theme={themes[theme]}>
            <GlobalStyle />
            {/* <SettingsCard themeHandler={themeHandler} curTheme={theme} /> */}
            {children}
        </ThemeProvider>
    );
};

export default Theme;
