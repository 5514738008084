import { FC, useState } from "react";
import ProfileDropdown from "../../../src/components/src/header/profile-dropdown/index"
import Notifications from "../notifications/Notifications";
import Messages from "../messages/Messages";
import Logo from "../logo/Logo";
import SearchIcon from '@mui/icons-material/Search';
import {
    StyledHeader,
    StyledLogo,
    StyledNavbarWrap,
    StyleNavbarRight,
    StyledNavbarElement,
    StyledSearchBox,
    StyledVerticalDivider,
    NAV_ELEMENT_MARGINS
} from "./style";
import { TextField, InputAdornment } from "@mui/material";

interface IProps {
    hasSidebar?: boolean;
    sidebarLayout?: 1 | 2;
}

const Nav: FC<IProps> = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };
    return (
        <>
            <StyledHeader>
                <StyledLogo>
                    <Logo />
                </StyledLogo>
                <StyledNavbarWrap>
                    <StyledSearchBox>
                        <TextField
                            fullWidth
                            placeholder="Ara"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: {
                                    height: '2.8em',
                                },
                                sx: {
                                    '& .MuiOutlinedInput-input': {
                                        padding: '0px',
                                    },
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StyledSearchBox>
                </StyledNavbarWrap>

                <StyleNavbarRight>
                    <StyledNavbarElement ml={NAV_ELEMENT_MARGINS.default}>
                        <Messages/>
                    </StyledNavbarElement>
                    <StyledVerticalDivider />
                    <StyledNavbarElement ml={NAV_ELEMENT_MARGINS.notification}>       
                        <Notifications/>
                    </StyledNavbarElement>
                    <StyledNavbarElement ml={NAV_ELEMENT_MARGINS.default}>
                        <ProfileDropdown />
                    </StyledNavbarElement>
                </StyleNavbarRight>
            </StyledHeader>
        </>
    );
};

Nav.defaultProps = {
    sidebarLayout: 1,
};

export default Nav;
