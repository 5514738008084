import { FC, useState, useReducer, useEffect, } from "react";
import { X } from "react-feather";
import {
  Modal,
  ModalTitle,
  ModalClose,
  ModalFooter,
} from "../../../components/src/ui/modal-three/modalthree";
import { TextField, colors } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import axios, { AxiosError } from 'axios';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
// import { getMainTeknoparkGroupList } from "../../redux/actions/memberAction";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import React from "react";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import { SERVICEURL } from "../../../appconfig";
import { Col, Row, Button } from "../../../components";
import { getTechnoparkNameList, getAllTeknoparkList, getRelatedMemberNameList } from "../../../redux/actions/memberAction";
import { ModalBody, ModalHeader } from "../../../../src/components/src/ui/modal/modal";
import CurrencyInput from "react-currency-input-field";
import { useCommonStyles } from '../../../styles/commonStyles';

interface IProps {
  show: boolean;

  onClose: () => void;
  getTechnoparkName: () => void;
}
const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      position: 'absolute',
      width: '92%',
      color: 'black',
      height: '98%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px'
    },
    formControl2: {
      position: 'relative',
      width: '100%',
      color: 'black',
      height: '230%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px'
    },
    formControlTop: {
      position: 'absolute',
      width: '92%',
      color: 'black',
      height: '98%',
      borderRadius: '4px',
      borderColor: '#AEADAD',
      fontSize: '16px',
      marginTop: '20px'
    },
    inputControl2: {
      position: "relative",
      width: "100%",
      color: "black",
      height: "54px",
      borderRadius: "4px",
      borderColor: "#AEADAD",
      fontSize: "16px",
    },
    labelControl: {
      position: "relative",
      width: "100%",
      color: "#959393",
      //height: '3.2 rem',

      fontSize: "12px",
    },
  })
);
const ModalTeknopark: FC<IProps> = ({ show, onClose, getTechnoparkName }) => {
  const dis = useDispatch();
  type State = {
    name: string;
    shortName: string;
    relatedMemberId: number;
    area: string;
    totalCapacity: number | null;
    rent: string;
    rentStartDate: Date | null;
    rentFinishDate: Date | null;
    dues: string;
    duesStartDate: Date | null;
    duesFinishDate: Date | null;
    address: string;
    statement: string;
    isActive: number | null;
  }
  const initialState: State = {
    name: '',
    shortName: '',
    relatedMemberId: 0,
    area: '',
    totalCapacity: null,
    rent: '',
    rentStartDate: null,
    rentFinishDate: null,
    dues: '',
    duesStartDate: null,
    duesFinishDate: null,
    address: '',
    statement: '',
    isActive: null
  }
  type Action =
    | { type: 'setName'; payload: string }
    | { type: 'setShortName'; payload: string }
    | { type: 'setRelatedMemberId'; payload: number }
    | { type: 'setArea'; payload: string }
    | { type: 'setTotalCapacity'; payload: number | null }
    | { type: 'setRent'; payload: string }
    | { type: 'setRentStartDate'; payload: Date }
    | { type: 'setRentFinishDate'; payload: Date }
    | { type: 'setDues'; payload: string }
    | { type: 'setDuesStartDate'; payload: Date }
    | { type: 'setDuesFinishDate'; payload: Date }
    | { type: 'setAddress'; payload: string }
    | { type: 'setStatement'; payload: string }
    | { type: 'setIsActive'; payload: number | null }

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'setName':
        return {
          ...state,
          name: action.payload,
        };
      case 'setShortName':
        return {
          ...state,
          shortName: action.payload,
        };
      case 'setRelatedMemberId':
        return {
          ...state,
          relatedMemberId: action.payload,
        };
      case 'setArea':
        return {
          ...state,
          area: action.payload,
        };
      case 'setTotalCapacity':
        return {
          ...state,
          totalCapacity: action.payload,
        };

      case 'setRent':
        return {
          ...state,
          rent: action.payload,
        };

      case 'setRentStartDate':
        return {
          ...state,
          rentStartDate: action.payload,
        };


      case 'setRentFinishDate':
        return {
          ...state,
          rentFinishDate: action.payload,
        };


      case 'setDues':
        return {
          ...state,
          dues: action.payload,
        };

      case 'setDuesStartDate':
        return {
          ...state,
          duesStartDate: action.payload,
        };

      case 'setDuesFinishDate':
        return {
          ...state,
          duesFinishDate: action.payload,
        };
      case 'setAddress':
        return {
          ...state,
          address: action.payload,
        };
      case 'setStatement':
        return {
          ...state,
          statement: action.payload,
        };
      case 'setIsActive':
        return {
          ...state,
          isActive: action.payload
        };
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  const [statusOptionName, setStatusOptionName] = useState("");

  const statusOptions = [
    { id: 1, name: 'Aktif' },
    { id: 0, name: 'Pasif' }
  ];

  const [relatedMemberValue, setRelatedMemberValue] = React.useState<any | null>();
  const [relatedMemberOptionName, setRelatedMemberOptionName] = useState("");
  const [relatedMemberInputName, setRelatedMemberInputName] = useState("");

  const { relatedMemberNameList } = useSelector((state: RootStateOrAny) => state.relatedMemberNameList);

  function getRelatedMemberName() {
    dis(getRelatedMemberNameList());
  }

  useEffect(() => {
    getRelatedMemberName();
  }, []);

  const handleSave = () => {

    if (state.name != "" && state.shortName != "" && relatedMemberValue != 0 && state.isActive != null && state.totalCapacity != null) {

      const mytoken = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + mytoken,
        },
      };
      const input = {
        Name: state.name,
        ShortName: state.shortName,
        RelatedMemberId: relatedMemberValue,
        Area: state.area,
        TotalCapacity: state.totalCapacity,
        Rent: String(state.rent),
        RentStartDate: startRentMonthYear,
        RentFinishDate: endRentMonthYear,
        Dues: String(state.dues),
        DuesStartDate: startDuesMonthYear,
        DuesFinishDate: endDuesMonthYear,
        Address: state.address,
        Statement: state.statement,
        IsActive: state.isActive
      };
      
      axios
        .post(SERVICEURL + 'api/Technopark/AddTechnoparkInfo?', input, config)
        .then((response) => {
          if (response.data.IsOk) {
            setOpen(true);
            getTechnoparkName();
          }
          else {
            setMessage(response.data.Message);
            setOpenError(true);
          }

        })
        .catch((error: AxiosError) => { console.log(error.message); });
    }
    else {
      setOpenWarning(true);
    }
  }
  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
    cancelForm();
  };
  const handleCloseWarning = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenWarning(false);
    setOpenError(false);

  };
  const cancelForm = () => {
    state.name = ""
    dispatch({
      type: 'setName',
      payload: state.name
    });
    state.shortName = ""
    dispatch({
      type: 'setShortName',
      payload: state.shortName
    });
    state.relatedMemberId = 0
    dispatch({
      type: 'setRelatedMemberId',
      payload: state.relatedMemberId
    });
    state.area = ""
    dispatch({
      type: 'setArea',
      payload: state.area
    });
    state.totalCapacity = null
    dispatch({
      type: 'setTotalCapacity',
      payload: state.totalCapacity
    });
    state.rent = ""
    dispatch({
      type: 'setRent',
      payload: state.rent
    });
    state.dues = ""
    dispatch({
      type: 'setDues',
      payload: state.dues
    });
    state.address = ""
    dispatch({
      type: 'setAddress',
      payload: state.address
    });
    state.statement = ""
    dispatch({
      type: 'setStatement',
      payload: state.statement
    });
    setEndRentMonthYear(null);
    setStartRentMonthYear(null);
    setEndDuesMonthYear(null);
    setStartDuesMonthYear(null);
    setRelatedMemberValue(0);
    setRelatedMemberInputName("");
    setRelatedMemberOptionName("");
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    cancelForm();
  };

  const OnCloseandDelete = () => {
    onClose();
    cancelForm();
  }

  const handleChangeRelatedMember = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setRelatedMemberValue(newValue.id);
      dispatch({
        type: "setRelatedMemberId",
        payload: newValue.id,
      });
    }
    if (newValue == null) {
      setRelatedMemberValue(newValue);
      newValue = 0;
      dispatch({
        type: "setRelatedMemberId",
        payload: newValue,
      });
    }
  };
  const handleChangeRelatedMemberInput = (
    event: any,
    newValueInput: any | null
  ) => {
    if (newValueInput != null && newValueInput != undefined) {
      setRelatedMemberOptionName(newValueInput);
      setRelatedMemberInputName(newValueInput);
    }
    if (newValueInput == null) {
      setRelatedMemberInputName(newValueInput);
    }
  };
  const handleChangeTechnoparkName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    dispatch({
      type: 'setName',
      payload: event.target.value,
    });
  };
  const handleChangeShortName = (
    event: any
  ) => {
    dispatch({
      type: 'setShortName',
      payload: event.target.value,
    });
  };
  const handleChangeArea: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setArea',
      payload: event.target.value,
    });
  };
  const handleChangeTotalCapacity: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setTotalCapacity',
      payload: event.target.value,
    });
  };
  const handleChangeRent = (value: any) => {
    dispatch({
      type: 'setRent',
      payload: value,
    });
  };
  const handleChangeDues = (value: any) => {
    dispatch({
      type: 'setDues',
      payload: value,
    });
  };
  const handleChangeAdress: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setAddress',
      payload: event.target.value,
    });
  };

  const handleChangeStatement: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    dispatch({
      type: 'setStatement',
      payload: event.target.value,
    });
  };

  const handleChangeIsActive = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      dispatch({
        type: "setIsActive",
        payload: newValue.props.value,
      });
    }
  };

  const [startRentMonthYear, setStartRentMonthYear] = useState<Date | null>(null);
  const [endRentMonthYear, setEndRentMonthYear] = useState<Date | null>(null);
  const [startDuesMonthYear, setStartDuesMonthYear] = useState<Date | null>(null);
  const [endDuesMonthYear, setEndDuesMonthYear] = useState<Date | null>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  //Saat farkından dolayı backende 1 gün eksik düşüyor,bu nedenle saat eklendi.
  startRentMonthYear?.setHours(5, 30, 0, 0);
  endRentMonthYear?.setHours(23, 0, 0, 0);
  startDuesMonthYear?.setHours(5, 30, 0, 0);
  endDuesMonthYear?.setHours(23, 0, 0, 0);


  const classesSelect = useStylesSelect();
  const commonStyles = useCommonStyles();
  const handleModalClick = () => {
  };
  return (
    <>
      <Modal show={show} onClose={handleModalClick}>
        <form>
          <ModalHeader>
            <ModalTitle>Teknopark Ekle</ModalTitle>
            <ModalClose onClose={OnCloseandDelete}>
              <X />
            </ModalClose>
          </ModalHeader>

          <ModalBody>
            {<Row>
              <Col lg={3} md sm={12} xs={11} mb={25}>

                {Object.values(relatedMemberNameList).length > 0 && (
                  <Autocomplete
                    options={Object.values(relatedMemberNameList.data.PersonnelList).map((item: any) => item)}
                    getOptionLabel={(options) => options.name == undefined ? relatedMemberOptionName : options.name}
                    value={relatedMemberValue == null ? 0 : relatedMemberValue}
                    onChange={handleChangeRelatedMember}
                    inputValue={relatedMemberInputName}
                    onInputChange={handleChangeRelatedMemberInput}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        variant="outlined"
                        {...params}
                        label="Yetkili Kişi"
                      />
                    )}
                  />
                )}

              </Col >
              <Col lg={3} md sm xs mb={10}>
                <Autocomplete
                  autoHighlight
                  options={statusOptions}
                  getOptionLabel={(options) => options.name == undefined ? statusOptionName : options.name}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: "setIsActive",
                        payload: newValue.id,
                      });
                    }
                  }}
                  disablePortal={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Proje Durumu"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11} mb={25}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Teknopark Adı"
                  variant="outlined"
                  value={state.name}
                  onChange={handleChangeTechnoparkName}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11} mb={25}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="T. Kısaltılmış Ad"
                  variant="outlined"
                  value={state.shortName}
                  onChange={handleChangeShortName}
                />
              </Col>

            </Row>}

            <Row>
              <Col lg md sm={6} xs={6} mb={25}>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startRentMonthYear}
                  onChange={(date: Date | null) => setStartRentMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                  placeholderText="Kira Baş. Tarihi"
                />
              </Col>
              <Col lg md sm={6} xs={6} mb={25}>

                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startDuesMonthYear}
                  onChange={(date: Date | null) => setStartDuesMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                  placeholderText="Aidat Baş. Tarihi"
                />
              </Col>
              <Col lg md sm={6} xs={6} mb={25}>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={endRentMonthYear}
                  onChange={(date: Date | null) => setEndRentMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                  placeholderText="Kira Bitiş Tarihi"
                />
              </Col>
              <Col lg md sm={6} xs={6} mb={25}>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={endDuesMonthYear}
                  onChange={(date: Date | null) => setEndDuesMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                  placeholderText="Aidat Bitiş Tarihi"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} md sm={12} xs={11} mb={25}>
                <TextField
                  fullWidth
                  label="Alan"
                  variant="outlined"
                  value={state.area}
                  onChange={handleChangeArea}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ zIndex:0 }}
                />
              </Col>
              <Col lg={3} md sm={12} xs={11} mb={25}>                <CurrencyInput
                  className={classesSelect.inputControl2}
                  id="input-example"
                  name="input-name"
                  prefix=""
                  value={state.rent}
                  groupSeparator="."
                  decimalSeparator=","
                  onValueChange={handleChangeRent}
                  placeholder="m2 başına kira"
                />
                {/* <TextField
                  fullWidth
                  label="m^2 kira"
                  variant="outlined"
                  value={state.rent}
                  onChange={handleChangeRent}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}
              </Col>
              <Col lg={3} md sm={12} xs={11} mb={25}>                <CurrencyInput
                  className={classesSelect.inputControl2}
                  id="input-example"
                  name="input-name"
                  prefix=""
                  value={state.dues}
                  groupSeparator="."
                  decimalSeparator=","
                  onValueChange={handleChangeDues}
                  placeholder="m2 başına aidat"
                />
                {/* <TextField
                  fullWidth
                  label="m^2 aidat"
                  variant="outlined"
                  value={state.dues}
                  onChange={handleChangeDues}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}
              </Col>
              <Col lg={3} md sm={12} xs={11} mb={25}>
                <TextField
                  fullWidth
                  label="Toplam Kapasite"
                  variant="outlined"
                  value={state.totalCapacity}
                  onChange={handleChangeTotalCapacity}
                  onKeyPress={(event) => {
                    if (!/[0-9\.\,]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{ zIndex:0 }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg md sm={11} xs={11} mb={18}>
                <TextField
                  fullWidth
                  label="Adres"
                  variant="outlined"
                  value={state.address}
                  onChange={handleChangeAdress}
                  style={{ zIndex:0 }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg md sm={11} xs={11}>
                <TextField
                  variant="outlined"
                  label="Açıklama"
                  value={state.statement}
                  onChange={handleChangeStatement}
                  style={{ width: "100%", marginTop: "10px",zIndex:0 }}
                  InputProps={{
                    style: {
                      height: "80px"
                    }
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              className={commonStyles.clearButton}
              onClick={() => handleDeleteModal()}>
              Temizle
            </Button>
            <Button
              size="lg"
              className={commonStyles.saveButton}
              onClick={handleSave}>
              Kaydet
            </Button>
            <Snackbar open={open} autoHideDuration={2000}
              onClose={handleClose}>
              <Alert onClose={() => handleClose} severity="success" sx={{ width: '200%' }}>
                Başarıyla kaydedildi.
              </Alert>
            </Snackbar>
            <Snackbar open={openWarning} autoHideDuration={2000}
              onClose={handleCloseWarning}>
              <Alert onClose={() => handleCloseWarning} severity="warning" sx={{ width: '200%' }}>
                Zorunlu alanları doldurun.
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={2000}
              onClose={handleCloseWarning}>
              <Alert onClose={() => handleCloseWarning} severity="warning" sx={{ width: '200%' }}>
                {message}
              </Alert>
            </Snackbar>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}


export default ModalTeknopark;
