import React, { useState, useMemo } from 'react';
import { TableSortLabel, TableCell, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export type Order = 'asc' | 'desc';

interface SortConfig<T extends string> {
  orderBy: T | null;
  order: Order;
}

interface TableSortProps<T extends string> {
  data: any[];
  initialSort?: SortConfig<T>;
  customComparators?: Partial<Record<T, (a: any, b: any) => number>>;
  onSortedDataChange: (sortedData: any[]) => void;
}

const parseDateString = (dateStr: string): Date => {
  if (!dateStr) return new Date(0);
  // DD/MM/YYYY formatını parse et
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day); // ay 0-based olduğu için month-1
};

const isValidDate = (date: any): boolean => {
  if (!date) return false;
  // DD/MM/YYYY formatı kontrolü
  if (typeof date === 'string' && date.includes('/')) {
    const [day, month, year] = date.split('/').map(Number);
    const parsedDate = new Date(year, month - 1, day);
    return !isNaN(parsedDate.getTime());
  }
  return !isNaN(new Date(date).getTime());
};

export const useTableSort = <T extends string>({
  data,
  initialSort = { orderBy: null, order: 'asc' },
  customComparators,
  onSortedDataChange,
}: TableSortProps<T>) => {
  const [order, setOrder] = useState<Order>(initialSort.order);
  const [orderBy, setOrderBy] = useState<T | null>(initialSort.orderBy);

  const handleRequestSort = (property: T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    if (!orderBy) {
      return data;
    }

    const comparator = (a: any, b: any) => {
      if (!a || !b) return 0;

      // Özel karşılaştırıcı varsa onu kullan
      if (customComparators?.[orderBy]) {
        return order === 'asc' 
          ? customComparators[orderBy]!(a, b)
          : -customComparators[orderBy]!(a, b);
      }

      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // Tarih kontrolü ve sıralaması
      if (isValidDate(aValue) && isValidDate(bValue)) {
        const dateA = typeof aValue === 'string' && aValue.includes('/') 
          ? parseDateString(aValue)
          : new Date(aValue);
        const dateB = typeof bValue === 'string' && bValue.includes('/') 
          ? parseDateString(bValue)
          : new Date(bValue);
        
        return order === 'asc' 
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      // Sayısal değerler için
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // String değerleri için
      aValue = aValue?.toString() || '';
      bValue = bValue?.toString() || '';
      
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    };

    const sorted = [...data].sort(comparator);
    onSortedDataChange(sorted);
    return sorted;
  }, [data, order, orderBy, customComparators, onSortedDataChange]);

  const SortableTableHeader: React.FC<{
    label: string;
    property: T;
    align?: 'left' | 'center' | 'right';
    sx?: any;
  }> = ({ label, property, align = 'left', sx }) => (
    <TableCell 
      align={align} 
      sx={{ 
        color: '#666', 
        fontWeight: 500,
        padding: '16px 8px',
        ...sx 
      }}
    >
      <TableSortLabel
        active={true}
        direction={orderBy === property ? order : 'asc'}
        onClick={() => handleRequestSort(property)}
        sx={{
          width: '83px !important',
          display: 'inline-flex',
          whiteSpace: 'pre-wrap',
          '& .MuiTableSortLabel-icon': {
            opacity: '1 !important',
            color: orderBy === property ? 'rgb(0 0 0 / 29%)' : 'rgb(0 0 0 / 20%)',
          },
        }}
      >
        {label.split('<br/>').map((text, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {text}
          </React.Fragment>
        ))}
        {orderBy === property ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );

  return {
    sortedData,
    SortableTableHeader,
    order,
    orderBy,
  };
}; 