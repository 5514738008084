import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Chip,
  Tabs,
  Tab,
  Divider,
  Button,
  Breadcrumbs,
  Link,
  CircularProgress
} from '@mui/material';
import Nav from "../../layouts/nav/Nav";
import Content from "../../layouts/content";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ModalTeknopark from './modal/modal-teknopark';
import ModalUpdateTeknopark from './modal/modal-update';
import ModalUpdate from '../../pages/project/modal/ModalUpdate';
import HomeIcon from '@mui/icons-material/Home';
import { useTableSort, Order } from '../../components/src/components/shared/TableSortComponent';
import TeknoparkInfoTab from './teknoparkTabs/TeknoparkInfoTab';
import ProjectsTab from './teknoparkTabs/ProjectsTab';
import PersonnelTab from './teknoparkTabs/PersonnelTab';
import { useDispatch, useSelector } from 'react-redux';
import { getTeknoparkInfo, getTeknoparkProjects } from '../../redux/actions/memberAction';

interface TeknoparkDetailProps { }

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface Project {
  ProjectId: number;
  Name: string;
  ProjectStartDate: string;
  ProjectEndDate: string;
  ProjectStatusId: number;
  ProjectStatusName: string;
  TotalCapacity: number;
  ArgeCapacity: number;
  SupportCapacity: number;
  ProjectNo: number;
  ProjectEstEndDate: string;
  IsAdditionalTime: boolean;
}

const TeknoparkDetail: React.FC<TeknoparkDetailProps> = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { teknoparkInfo } = useSelector((state: any) => state.teknoparkInfo);
  const { projects: allProjects } = useSelector((state: any) => state.teknoparkProjects);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [showTeknoparkModal, setShowTeknoparkModal] = useState(false);
  const [showUpdateTeknoparkModal, setShowUpdateTeknoparkModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [projectStatus, setProjectStatus] = useState<string>('all');
  const [capacityStatus, setCapacityStatus] = useState<string>('all');
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [showProjectUpdateModal, setShowProjectUpdateModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [personnelPage, setPersonnelPage] = useState(0);
  const [personnelRowsPerPage, setPersonnelRowsPerPage] = useState(10);
  const [personnelFilters, setPersonnelFilters] = useState({
    title: '',
    position: '',
    bilisim: '',
    customer: ''
  });
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const [sortedProjects, setSortedProjects] = useState<Project[]>([]);

  useEffect(() => {
    setSortedProjects(filteredProjects);
  }, [filteredProjects]);

  const tableSortProps = useTableSort<keyof Project>({
    data: filteredProjects,
    onSortedDataChange: setSortedProjects,
  });

  useEffect(() => {
    if (id) {
      dispatch(getTeknoparkInfo(Number(id)));
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (tabValue === 1) {
      dispatch(getTeknoparkProjects(Number(id)));
    }
  }, [tabValue]);

  useEffect(() => {
    let result = [...allProjects];

    if (projectStatus !== 'all') {
      result = result.filter(project =>
        project.ProjectStatusId === Number(projectStatus)
      );
    }

    if (capacityStatus !== 'all') {
      result = result.filter(project => {
        const hasEmptyCapacity = project.ArgeCapacity > 0 || project.SupportCapacity > 0;
        return capacityStatus === 'empty' ? hasEmptyCapacity : !hasEmptyCapacity;
      });
    }

    setFilteredProjects(result);
  }, [projectStatus, capacityStatus, allProjects]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTeknoparkModal = () => {
    setShowTeknoparkModal(true);
  };

  const OnCloseModal = () => {
    setShowTeknoparkModal(false);
  };

  const OnCloseUpdateTeknoparkModal = () => {
    setShowUpdateTeknoparkModal(false);
  };

  const handleEdit = (id: number) => {
    dispatch(getTeknoparkInfo(Number(id)));
    setShowUpdateTeknoparkModal(true);
  };

  const modalUpdateProps = {
    technoparkId: teknoparkInfo?.TechnoparkId,
    name: teknoparkInfo?.Name,
    shortName: teknoparkInfo?.ShortName,
    relatedMemberId: teknoparkInfo?.RelatedMemberId,
    relatedMemberName: teknoparkInfo?.RelatedMemberName,
    area: teknoparkInfo?.Area,
    totalCapacity: teknoparkInfo?.TotalCapacity,
    rent: teknoparkInfo?.Rent,
    rentStartDate: teknoparkInfo?.RentStartDate,
    rentFinishDate: teknoparkInfo?.RentFinishDate,
    dues: teknoparkInfo?.Dues,
    duesStartDate: teknoparkInfo?.DuesStartDate,
    duesFinishDate: teknoparkInfo?.DuesFinishDate,
    address: teknoparkInfo?.Address,
    statement: teknoparkInfo?.Statement,
    isActive: teknoparkInfo?.IsActive,
    getAllTeknopark: () => dispatch(getTeknoparkInfo(Number(id)))
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProjectView = (project: Project) => {
    history.push(`/project/detail/${project.ProjectId}`, { projectData: project });
  };

  const handleCloseProjectModal = () => {
    setShowProjectUpdateModal(false);
    setSelectedProject(null);
  };

  const handleProjectEdit = (project: Project) => {
    setSelectedProject(project);
    setShowProjectUpdateModal(true);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div style={{ backgroundColor: 'rgb(247,250,255)'}}>
        <Content>
          <Container>
            <Box sx={{ mb: 3, mt: 6 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Ana Sayfa
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href="/teknopark"
                >
                  Teknoparklar
                </Link>
                <Typography color="text.primary">
                  {teknoparkInfo?.Name || 'Teknopark Detay'}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {teknoparkInfo?.Name}
                </Typography>
                <Chip
                  label={teknoparkInfo?.IsActive ? "Aktif" : "Pasif"}
                  color={teknoparkInfo?.IsActive ? "success" : "default"}
                  size="small"
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  sx={{
                    color: '#2E4670',
                    backgroundColor: 'rgb(247, 250, 255)',
                    boxShadow: 'none',
                    textTransform: 'none',
                    mr:2,
                    '&:hover': {
                      backgroundColor: 'white'
                    }
                  }}
                  onClick={() => handleEdit(Number(id))}
                >
                  Düzenle
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#2E4670',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#1e2f4a'
                    }
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleTeknoparkModal}
                >
                  Teknopark Ekle
                </Button>
              </Box>
            </Box>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body2" color="textSecondary">
                    Kapasitesi
                  </Typography>
                  <Typography variant="h4" sx={{color:"#2E4670"}}>
                    {teknoparkInfo?.TotalCapacity}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body2" color="textSecondary">
                    Proje Sayısı
                  </Typography>
                  <Typography variant="h4" sx={{color:"#2E4670"}}>{teknoparkInfo?.ProjectCount}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body2" color="textSecondary">
                    Personel Sayısı
                  </Typography>
                  <Typography variant="h4" sx={{color:"#2E4670"}}>{teknoparkInfo?.EmployeeCount}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                  <Typography variant="body2" color="textSecondary">
                    Kapasite
                  </Typography>
                  <Typography variant="h4" sx={{color:"#2E4670"}}>{teknoparkInfo?.TotalCapacity}</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Paper sx={{ mb: 4 }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Teknopark Bilgileri" />
                <Tab label="Projeler" />
                <Tab label="Personeller" />
              </Tabs>
              <Divider />

              <TabPanel value={tabValue} index={0}>
                <TeknoparkInfoTab teknoparkData={teknoparkInfo} />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <ProjectsTab
                  projectStatus={projectStatus}
                  setProjectStatus={setProjectStatus}
                  capacityStatus={capacityStatus}
                  setCapacityStatus={setCapacityStatus}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  sortedProjects={sortedProjects}
                  handleProjectEdit={handleProjectEdit}
                  handleProjectView={handleProjectView}
                  filteredProjects={filteredProjects}
                  tableSortProps={tableSortProps}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <PersonnelTab
                  technoparkId={Number(id)}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  personnelFilters={personnelFilters}
                  setPersonnelFilters={setPersonnelFilters}
                  personnelPage={personnelPage}
                  setPersonnelPage={setPersonnelPage}
                  personnelRowsPerPage={personnelRowsPerPage}
                  setPersonnelRowsPerPage={setPersonnelRowsPerPage}
                />
              </TabPanel>
            </Paper>
          </Container>
        </Content>
      </div>
      <ModalTeknopark
        show={showTeknoparkModal}
        onClose={OnCloseModal}
        getTechnoparkName={() => dispatch(getTeknoparkInfo(Number(id)))}
      />
      <ModalUpdateTeknopark
        show={showUpdateTeknoparkModal}
        onClose={OnCloseUpdateTeknoparkModal}
        {...modalUpdateProps}
      />
      {selectedProject && (
        <ModalUpdate
          show={showProjectUpdateModal}
          onClose={handleCloseProjectModal}
          projectId={selectedProject.ProjectId}
          name={selectedProject.Name}
          nameId={selectedProject.ProjectId}
          projectNo={selectedProject.ProjectNo}
          technoparkId={Number(id)}
          technoparkName={teknoparkInfo?.Name}
          relatedMemberId={teknoparkInfo?.RelatedMemberId}
          relatedMemberName={teknoparkInfo?.RelatedMemberName}
          argeCapacity={selectedProject.ArgeCapacity}
          supportCapacity={selectedProject.SupportCapacity}
          projectStartDate={selectedProject.ProjectStartDate}
          projectEndDate={selectedProject.ProjectEndDate}
          projectEstEndDate={selectedProject.ProjectEstEndDate}
          projectStatusId={selectedProject.ProjectStatusId}
          projectStatusName={selectedProject.ProjectStatusName}
          isAdditionalTime={selectedProject.IsAdditionalTime}
          count={0}
          getAllProject={() => dispatch(getTeknoparkProjects(Number(id)))}
          getCapacity={() => { }}
        />
      )}
    </>
  );
};

export default TeknoparkDetail; 