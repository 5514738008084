import { ActionCreator } from "redux";
import {
  GET_COST_CENTER_ERROR,
  GET_COST_CENTER_SUCCESS,
  GET_MY_USER_DETAIL_SUCCESS,
  GET_MY_USER_DETAIL_ERROR,
  CostCenter,
  Member,
  memberActions,
  Companies,
  GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_ERROR,
  CurrentDebt,
  GET_CURRENT_DEBT_LİST_SUCCESS,
  GET_ALL_PASSIVE_CUSTOMER_SUCCESS,
  GET_ALL_PASSIVE_CUSTOMER_ERROR,
  GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS,
  GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR,
  GET_CURRENT_DEBT_LİST_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_MAİN_COST_CENTER_GROUP_SUCCESS,
  GET_MAİN_COST_CENTER_GROUP_ERROR,
  MainCostCenter,
  GET_SUB_COST_CENTER_GROUP_SUCCESS,
  GET_SUB_COST_CENTER_GROUP_ERROR,
  SubCostCenter,
  GET_COST_CENTER_LİST_SUCCESS,
  GET_COST_CENTER_LİST_ERROR,
  CostCenterList,
  GET_COST_CENTER_DETAIL_SUCCESS,
  GET_COST_CENTER_DETAIL_ERROR,
  CostCenterDetail,
  Firm,
  GET_FİRM_NAMES_ERROR,
  GET_FİRM_NAMES_SUCCESS,
  GET_STARTİNG_WORK_EMPLOYEES_SUCCESS,
  GET_STARTİNG_WORK_EMPLOYEES_ERROR,
  StartingWorkEmployees,
  GET_TECHNOPARK_NAME_LİST_SUCCESS,
  GET_TECHNOPARK_NAME_LİST_ERROR,
  GET_RELATED_MEMBER__NAME_LİST_SUCCESS,
  GET_RELATED_MEMBER__NAME_LİST_ERROR,
  TechnoparkNameList,
  RelatedMemberNameList,
  GET_PERSONAL_TYPE_LİST_SUCCESS,
  GET_PERSONAL_TYPE_LİST_ERROR,
  PersonalTypeList,
  GET_PROJECT_NAME_LİST_SUCCESS,
  GET_PROJECT_NAME_LİST_ERROR,
  ProjectNameList,
  GET_PROJECT_STATUS_LİST_SUCCESS,
  GET_PROJECT_STATUS_LİST_ERROR,
  TeknoparkList,
  GET_TEKNOPARK_LİST_SUCCESS,
  GET_TEKNOPARK_LİST_ERROR,
  UPDATE_TEKNOPARK_LİST_SUCCESS,
  UPDATE_TEKNOPARK_LİST_ERROR,
  GET_PROJECT_ASSİGNMENT_LİST_SUCCESS,
  GET_PROJECT_ASSİGNMENT_LİST_ERROR,
  ProjectAssignmentList,
  CustomerList,
  PassiveCustomerList,
  PersonnelNameForProjectAssignmentList,
  GET_LEAVİNG_PERSONAL_LİST_SUCCESS,
  GET_LEAVİNG_PERSONAL_LİST_ERROR,
  LeavingPersonalList,
  TeknoparkUpdateList,
  GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS,
  GET_ALL_TECHNOPARK_NAME_LİST_ERROR,
  AllTechnoparkNameList,
  GET_BAR_CHART_SUCCESS,
  GET_BAR_CHART_ERROR,
  GET_PIE_CHART_SUCCESS,
  GET_PIE_CHART_ERROR,
  GET_TEKNOPARK_DETAIL_SUCCESS,
  GET_TEKNOPARK_DETAIL_ERROR,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_DETAIL_ERROR,
  GET_PROJECT_STATUS_PARAMETER_SUCCESS,
  GET_PROJECT_STATUS_PARAMETER_ERROR,
  GET_CAPACITY_INFO_SUCCESS,
  GET_CAPACITY_INFO_ERROR,
  GET_TEKNOPARK_INFO_SUCCESS,
  GET_TEKNOPARK_INFO_ERROR,
  GET_TEKNOPARK_PROJECTS_SUCCESS,
  GET_TEKNOPARK_PROJECTS_ERROR,
  GET_TEKNOPARK_AND_PROJECT_INFO_SUCCESS,
  GET_TEKNOPARK_AND_PROJECT_INFO_ERROR,
  GET_PROJECT_PERSONNEL_SALARY_SUCCESS,
  GET_PROJECT_PERSONNEL_SALARY_ERROR,
} from "../../redux/actions/actionTypes";
import axios, { AxiosError } from "axios";
import { SERVICEURL } from "../../appconfig";
import { currentDebtReducer } from "../reducers/memberList";
//Başarılıysa reducer'a GET_TASK_LIST_SUCCESS action'un ve parametresini döndürüyoruz.

const getCostCenterListSuccess: ActionCreator<memberActions> = (
  list: CostCenter[]
) => {
  return { type: GET_COST_CENTER_SUCCESS, payload: list };
};

const getCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_ERROR, payload: message };
};

const getCompaniesListSuccess: ActionCreator<memberActions> = (
  list: Companies[]
) => {
  return { type: GET_COMPANIES_SUCCESS, payload: list };
};

const getCompaniesListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COMPANIES_ERROR, payload: message };
};

const getCurrentDebtListSuccess: ActionCreator<memberActions> = (
  list: CurrentDebt[]
) => {
  return { type: GET_CURRENT_DEBT_LİST_SUCCESS, payload: list };
};
const getCurrentDebtListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_CURRENT_DEBT_LİST_ERROR, payload: message };
};
let getMyUserDetailListSuccess: ActionCreator<memberActions> = (
  list: Member[]
) => {
  return { type: GET_MY_USER_DETAIL_SUCCESS, payload: list };
};
const getMyUserDetailListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_MY_USER_DETAIL_ERROR, payload: message };
};
const getMainCostCenterGroupSuccess: ActionCreator<memberActions> = (
  list: MainCostCenter[]
) => {
  return { type: GET_MAİN_COST_CENTER_GROUP_SUCCESS, payload: list };
};
const getMainCostCenterGroupError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_MAİN_COST_CENTER_GROUP_ERROR, payload: message };
};
const getSubCostCenterGroupSuccess: ActionCreator<memberActions> = (
  list: SubCostCenter[]
) => {
  return { type: GET_SUB_COST_CENTER_GROUP_SUCCESS, payload: list };
};
const getSubCostCenterGroupError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_SUB_COST_CENTER_GROUP_ERROR, payload: message };
};

const getAllCostCenterListSuccess: ActionCreator<memberActions> = (
  list: CostCenterList[]
) => {
  return { type: GET_COST_CENTER_LİST_SUCCESS, payload: list };
};

const getAllCostCenterListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_LİST_ERROR, payload: message };
};

const getAllTeknoparkListSuccess: ActionCreator<memberActions> = (
  list: TeknoparkList[]
) => {
  return { type: GET_TEKNOPARK_LİST_SUCCESS, payload: list };
};

const getAllTeknoparkListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_TEKNOPARK_LİST_ERROR, payload: message };
};
const updateTeknoparkListSuccess: ActionCreator<memberActions> = (
  list: TeknoparkUpdateList[]
) => {
  return { type: UPDATE_TEKNOPARK_LİST_SUCCESS, payload: list };
};

const updateTeknoparkListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: UPDATE_TEKNOPARK_LİST_ERROR, payload: message };
};

const getCostCenterDetailSuccess: ActionCreator<memberActions> = (
  list: CostCenterDetail[]
) => {
  return { type: GET_COST_CENTER_DETAIL_SUCCESS, payload: list };
};
const getCostCenterDetailError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_COST_CENTER_DETAIL_ERROR, payload: message };
};
const getFirmNamesSuccess: ActionCreator<memberActions> = (list: Firm[]) => {
  return { type: GET_FİRM_NAMES_SUCCESS, payload: list };
};
const getFirmNamesError: ActionCreator<memberActions> = (message: string) => {
  return { type: GET_FİRM_NAMES_ERROR, payload: message };
};
const getStartingWorkEmployeesSuccess: ActionCreator<memberActions> = (
  list: StartingWorkEmployees[]
) => {
  return { type: GET_STARTİNG_WORK_EMPLOYEES_SUCCESS, payload: list };
};
const getStartingWorkEmployeesError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_STARTİNG_WORK_EMPLOYEES_ERROR, payload: message };
};
const getTechnoparkNameListSuccess: ActionCreator<memberActions> = (
  list: TechnoparkNameList[]
) => {
  return { type: GET_TECHNOPARK_NAME_LİST_SUCCESS, payload: list };
};
const getTechnoparkNameListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_TECHNOPARK_NAME_LİST_ERROR, payload: message };
};

const getRelatedMemberNameListSuccess: ActionCreator<memberActions> = (
  list: RelatedMemberNameList[]
) => {
  return { type: GET_RELATED_MEMBER__NAME_LİST_SUCCESS, payload: list };
};
const getRelatedMemberListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_RELATED_MEMBER__NAME_LİST_ERROR, payload: message };
};
const getPersonalTypeListSuccess: ActionCreator<memberActions> = (
  list: PersonalTypeList[]
) => {
  return { type: GET_PERSONAL_TYPE_LİST_SUCCESS, payload: list };
};
const getPersonalTypeListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PERSONAL_TYPE_LİST_ERROR, payload: message };
};
const getProjectNameListSuccess: ActionCreator<memberActions> = (
  list: ProjectNameList[]
) => {
  return { type: GET_PROJECT_NAME_LİST_SUCCESS, payload: list };
};
const getProjectNameListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PROJECT_NAME_LİST_ERROR, payload: message };
};
const getProjectAssignmentListSuccess: ActionCreator<memberActions> = (
  list: ProjectAssignmentList[]
) => {
  return { type: GET_PROJECT_ASSİGNMENT_LİST_SUCCESS, payload: list };
};
const getProjectAssignmentListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_PROJECT_ASSİGNMENT_LİST_ERROR, payload: message };
};

const getCustomerListSuccess: ActionCreator<memberActions> = (
  list: CustomerList[]
) => {
  return { type: GET_ALL_CUSTOMER_SUCCESS, payload: list };
};

const getCustomerListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_ALL_CUSTOMER_ERROR, payload: message };
};

const getPassiveCustomerListSuccess: ActionCreator<memberActions> = (
  list: PassiveCustomerList[]
) => {
  return { type: GET_ALL_PASSIVE_CUSTOMER_SUCCESS, payload: list };
};

const getPassiveCustomerListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_ALL_PASSIVE_CUSTOMER_ERROR, payload: message };
};
const getPersonnelNameForProjectAssignmentSuccess: ActionCreator<
  memberActions
> = (list: PersonnelNameForProjectAssignmentList[]) => {
  return {
    type: GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS,
    payload: list,
  };
};

const getPersonnelNameForProjectAssignmentError: ActionCreator<
  memberActions
> = (message: string) => {
  return {
    type: GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR,
    payload: message,
  };
};
const getLeavingPersonalListSuccess: ActionCreator<memberActions> = (
  list: LeavingPersonalList[]
) => {
  return { type: GET_LEAVİNG_PERSONAL_LİST_SUCCESS, payload: list };
};

const getLeavingPersonalListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_LEAVİNG_PERSONAL_LİST_ERROR, payload: message };
};

const getAllTechnoparkNameListSuccess: ActionCreator<memberActions> = (
  list: AllTechnoparkNameList[]
) => {
  return { type: GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS, payload: list };
};
const getAllTechnoparkNameListError: ActionCreator<memberActions> = (
  message: string
) => {
  return { type: GET_ALL_TECHNOPARK_NAME_LİST_ERROR, payload: message };
};

const getBarChartSuccess = (data: any): memberActions => ({
  type: GET_BAR_CHART_SUCCESS,
  payload: data,
});

const getBarChartError = (message: string): memberActions => ({
  type: GET_BAR_CHART_ERROR,
  payload: message,
});

const getPieChartSuccess = (data: any): memberActions => ({
  type: GET_PIE_CHART_SUCCESS,
  payload: data,
});

const getPieChartError = (message: string): memberActions => ({
  type: GET_PIE_CHART_ERROR,
  payload: message,
});

const getTeknoparkDetailSuccess = (data: any): memberActions => ({
  type: GET_TEKNOPARK_DETAIL_SUCCESS,
  payload: data,
});

const getTeknoparkDetailError = (message: string): memberActions => ({
  type: GET_TEKNOPARK_DETAIL_ERROR,
  payload: message,
});

const getProjectDetailSuccess = (data: any): memberActions => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: data,
});

const getProjectDetailError = (message: string): memberActions => ({
  type: GET_PROJECT_DETAIL_ERROR,
  payload: message,
});

const getProjectStatusParameterSuccess = (data: any): memberActions => ({
  type: GET_PROJECT_STATUS_PARAMETER_SUCCESS,
  payload: data,
});

const getProjectStatusParameterError = (message: string): memberActions => ({
  type: GET_PROJECT_STATUS_PARAMETER_ERROR,
  payload: message,
});

const getCapacityInfoSuccess = (data: any): memberActions => ({
  type: GET_CAPACITY_INFO_SUCCESS,
  payload: data,
});

const getCapacityInfoError = (message: string): memberActions => ({
  type: GET_CAPACITY_INFO_ERROR,
  payload: message,
});

const mytoken = localStorage.getItem("token");
const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + mytoken,
  },
};

export function getCostCenterList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { memberId: name };
      axios
        .get(SERVICEURL + "api/Data/GetActiveCostCenter?", HEADER)
        .then((response) => {
          dispatch(getCostCenterListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getCompaniesList(state: { state: { name: string } }) {
  const name = state.state.name;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { memberId: name };

      axios
        .get(SERVICEURL + "api/Data/GetCompanies?", HEADER)
        .then((response) => {
          dispatch(getCompaniesListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}
export function getMyUserDetailList(state: { state: { id: number } }) {
  const id = state.state.id;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (id != null) {
      const input = { memberId: id };

      axios
        .post(SERVICEURL + "api/Membership/GetMyDetail?", input, HEADER)
        .then((response) => {
          dispatch(getMyUserDetailListSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}
export function getCurrentDebtList(state: {
  state: {
    firmName: string;
    costCenterCode: number;
    companies: number;
    debtDate: Date;
  };
}) {
  const firmName = state.state.firmName;
  //const currentDebtYear = state.state.currentDebtYear.toString();
  const costCenterCode = state.state.costCenterCode;
  const companies = state.state.companies;
  const debtDate = state.state.debtDate;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (
      firmName != null ||
      costCenterCode != null ||
      companies != null ||
      debtDate != null
    ) {
      const input = {
        CompanyId: companies,
        CostCenterParameterId: costCenterCode,
        FirmName: firmName,
        DebtDate: debtDate,
      };

      axios
        .post(SERVICEURL + "api/Finance/ListCurrentDebt", input, HEADER)
        .then((response) => {
          dispatch(getCurrentDebtListSuccess(response));
        })
        .catch((error) => {
          dispatch(getCurrentDebtListError("Save error."));
        });
    }
  };
}

export function getMainCostCenterGroupList(state: { state: { name: string } }) {
  const name = state.state.name;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { name: name };

      axios
        .get(SERVICEURL + "api/Data/GetMainCostCenterGroup?", HEADER)
        .then((response) => {
          dispatch(getMainCostCenterGroupSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getSubCostCenterGroupList(state: { state: { name: string } }) {
  const name = state.state.name;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { name: name };

      axios
        .get(SERVICEURL + "api/Data/GetSubCostCenterGroup?", HEADER)
        .then((response) => {
          dispatch(getSubCostCenterGroupSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getAllCostCenterList(state: {
  state: { companies: number; mainGroupName: string };
}) {
  const mainGroupName = state.state.mainGroupName;
  const companies = state.state.companies;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (mainGroupName != null || companies != null) {
      const input = { CompanyId: companies, MainGroupName: mainGroupName };

      axios
        .post(
          SERVICEURL + "api/Data/GetActiveCostCenterbyInput?",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(getAllCostCenterListSuccess(response));
        })
        .catch((error) => {
          dispatch(getAllCostCenterListError("Save error."));
        });
    }
  };
}

export function getCostCenterDetailList(state: { state: { name: string } }) {
  const name = state.state.name;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { name: name };

      axios
        .get(SERVICEURL + "api/Data/GetCostCenterDetail?", HEADER)
        .then((response) => {
          dispatch(getCostCenterDetailSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getFirmNamesList(state: { state: { firmName: string } }) {
  const name = state.state.firmName;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    if (name != null) {
      const input = { name: name };

      axios
        .get(SERVICEURL + "api/Data/GetFirmNames", HEADER)
        .then((response) => {
          dispatch(getFirmNamesSuccess(response));
        })
        .catch((reason: AxiosError) => {
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else: ", reason.message);
          }
        });
    }
  };
}

export function getStartingWorkEmployeesList(state: {
  personnelValue: number;
  state: { startDate: Date | null };
}) {
  const startDate = state.state.startDate;
  const personnelValue = state.personnelValue;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    const input = { startDate: startDate, MemberId: personnelValue };
    axios
      .post(
        SERVICEURL + "api/Technopark/GetStartingWorkEmployeesList?",
        input,
        HEADER
      )
      .then((response) => {
        dispatch(getStartingWorkEmployeesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getStartingWorkEmployeesError("Save error."));
      });
  };
}

export function getTechnoparkNameList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Data/GetTechnoparkNameList", HEADER)
      .then((response) => {
        dispatch(getTechnoparkNameListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else: ", reason.message);
        }
      });
  };
}

export function getRelatedMemberNameList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Data/GetIKPersonnel", HEADER)
      .then((response) => {
        dispatch(getRelatedMemberNameListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else: ", reason.message);
        }
      });
  };
}

export function getPersonalTypeList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Data/GetPersonalTypeList", HEADER)
      .then((response) => {
        dispatch(getPersonalTypeListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else: ", reason.message);
        }
      });
  };
}
export function getProjectNameList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Data/GetTechnoparkProjectNameList", HEADER)
      .then((response) => {
        dispatch(getProjectNameListSuccess(response));
      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else: ", reason.message);
        }
      });
  };
}

export function getCustomerList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Data/GetTechnoparkActiveCustomerList", HEADER)
      .then((response) => {
        dispatch(getCustomerListSuccess(response));
      })
      .catch((error) => {
        dispatch(getCustomerListError("Save error."));
      });
  };
}

export function getPassiveCustomerList() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Technopark/GetPassiveCustomerList", HEADER)
      .then((response) => {
        dispatch(getPassiveCustomerListSuccess(response));
      })
      .catch((error) => {
        dispatch(getPassiveCustomerListError("Save error."));
      });
  };
}

export function getPersonnelNameForProjectAssignmentList(
  teknoParkId: number | null,
  personnelTypeId: number | null
) {
  return (dispatch: (arg0: memberActions) => void) => {
    axios
      .get(SERVICEURL + "api/Data/GetPersonnelNameForProjectAssignment", {
        params: {
          TechnoparkId: teknoParkId,
          PersonnelTypeId: personnelTypeId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log("SEND");

        dispatch(getPersonnelNameForProjectAssignmentSuccess(response));
      })
      .catch((error) => {
        dispatch(getPersonnelNameForProjectAssignmentError("Save error."));
      });
  };
}

export function getAllTeknoparkList(state: {
  state: {
    technoparkId: number;
  };
}) {
  const technoparkId = state.state.technoparkId;

  return (dispatch: (arg0: memberActions) => void) => {
    if (technoparkId != null) {
      const input = {
        TechnoparkId: technoparkId,
      };
      axios
        .post(SERVICEURL + "api/Technopark/GetTechnoparkInfo?", input, HEADER)
        .then((response) => {
          dispatch(getAllTeknoparkListSuccess(response));
        })
        .catch((error) => {
          dispatch(getAllTeknoparkListError("Save error."));
        });
    }
  };
}

export function ModalUpdateTeknoparkList(state: {
  state: {
    technoparkId: number;
    name: string;
    shortName: string;
    relatedMemberId: number;
    area: string;
    totalCapacity: number;
    emptyCapacity: number;
    rent: string;
    rentStartDate: Date;
    rentFinishDate: Date;
    dues: string;
    duesStartDate: Date;
    duesFinishDate: Date;
    address: string;
    statement: string;
    isActive: boolean | null;
  };
}) {
  const technoparkId = state.state.technoparkId;
  const name = state.state.name;
  const shortName = state.state.shortName;
  const relatedMemberId = state.state.relatedMemberId;
  const area = state.state.area;
  const TotalCapacity = state.state.totalCapacity;
  const EmptyCapacity = state.state.emptyCapacity;
  const rent = state.state.rent;
  const rentStartDate = state.state.rentStartDate;
  const rentFinishDate = state.state.rentFinishDate;
  const dues = state.state.dues;
  const duesStartDate = state.state.duesStartDate;
  const duesFinishDate = state.state.duesFinishDate;
  const address = state.state.address;
  const statement = state.state.statement;
  const isActive = state.state.isActive;

  return (dispatch: (arg0: memberActions) => void) => {
    if (technoparkId != null) {
      const input = {
        technoparkId: technoparkId,
        name: name,
        shortName: shortName,
        relatedMemberId: relatedMemberId,
        area: area,
        totalCapacity: TotalCapacity,
        emptyCapacity: EmptyCapacity,
        rent: rent,
        rentStartDate: rentStartDate,
        rentFinishDate: rentFinishDate,
        dues: dues,
        duesStartDate: duesStartDate,
        duesFinishDate: duesFinishDate,
        address: address,
        statement: statement,
        isActive: isActive,
      };
      axios
        .post(
          SERVICEURL + "api/Technopark/UpdateTechnoparkInfo?",
          input,
          HEADER
        )
        .then((response) => {
          dispatch(updateTeknoparkListSuccess(response));
        })
        .catch((error) => {
          dispatch(updateTeknoparkListError("Save error."));
        });
    }
  };
}

export function getProjectAssignmentList(state: {
  period: Date | null;
  addPersonal: boolean;
  leavingPersonal: boolean;
  personalID: number;
  state: {
    technoparkId: number;
    personalTypeId: number;
    emptyCapacity: number | string ;
  };
}) {
  const addPersonal = state.addPersonal;
  const leavingPersonal = state.leavingPersonal;
  const personalID = state.personalID;
  const technoparkId = state.state.technoparkId;
  const personalTypeId = state.state.personalTypeId;
  const period = state.period;
  const emptyCapacity = state.state.emptyCapacity;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    const input = {
      Period: period,
      PersonalTypeId: personalTypeId,
      TechnoparkId: technoparkId,
      AddPersonal: addPersonal,
      LeavingPersonal: leavingPersonal,
      EmptyCapacity: emptyCapacity,
      PersonalID: personalID,
    };
    axios
      .post(
        SERVICEURL + "api/Technopark/GetProjectAssignmentList?",
        input,
        HEADER
      )
      .then((response) => {
        dispatch(getProjectAssignmentListSuccess(response));
      })
      .catch((error) => {
        dispatch(getProjectAssignmentListError("Save error."));
      });
  };
}

export function getLeavingPersonalList(state: {
  period: Date | null;
  state: { technoparkId: number };
}) {
  const technoparkId = state.state.technoparkId;
  const period = state.period;
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    const input = { Period: period, TechnoparkId: technoparkId };
    axios
      .post(
        SERVICEURL + "api/Technopark/GetLeavingPersonalList?",
        input,
        HEADER
      )
      .then((response) => {
        dispatch(getLeavingPersonalListSuccess(response));
      })
      .catch((error) => {
        dispatch(getLeavingPersonalListError("Save error."));
      });
  };
}
export function getAllTechnoparkNameList(state: {
  teknoparkPassive: { passive: boolean };
}) {
  const passive = state.teknoparkPassive.passive;

  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    const input = { Passive: passive };

    axios
      .post(SERVICEURL + "api/Data/GetAllTechnoparkNameList", input, HEADER)
      .then((response) => {
        dispatch(getAllTechnoparkNameListSuccess(response));
      })
      .catch((error) => {
        dispatch(getAllTechnoparkNameListError("Save error."));
      });
  };
}

export function getBarChartData() {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    axios
      .get(SERVICEURL + "api/Technopark/GetBarChartforHomePage", HEADER)
      .then((response) => {
        dispatch(getBarChartSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getBarChartError("Veri alınamadı."));
      });
  };
}

export function getPieChartData(technoparkId: number) {
  return async (dispatch: (arg0: memberActions) => void) => {
    try {
      const mytoken = localStorage.getItem("token");
      const response = await axios.post(
        SERVICEURL + "api/Technopark/GetTechnoparkProjectInfoDetail",
        { TechnoparkId: technoparkId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + mytoken,
          },
        }
      );
      dispatch(getPieChartSuccess(response.data));
    } catch (error: any) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        dispatch(getPieChartError(error.message));
      }
    }
  };
}

export function getTeknoparkDetail(id: number) {
  return (dispatch: (arg0: memberActions) => void) => {
    const mytoken = localStorage.getItem("token");
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };

    const input = { TechnoparkId: id };

    axios
      .post(SERVICEURL + "api/Technopark/GetTechnoparkInfo", input, HEADER)
      .then((response) => {
        dispatch(getTeknoparkDetailSuccess(response));
      })
      .catch((error) => {
        dispatch(getTeknoparkDetailError("Teknopark detay bilgileri alınamadı."));
      });
  };
}

export const getProjectDetail = (state: { technoparkId: number; projectId: number; projectStatusIds: number[] }) => {
  return async (dispatch: any) => {
    try {
      const mytoken = localStorage.getItem("token");
      const HEADER = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mytoken,
        },
      };

      const response = await axios.post(
        SERVICEURL + "api/Technopark/GetTechnoparkProjectInfoDetail",
        state,
        HEADER
      );

      dispatch(getProjectDetailSuccess(response.data));
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        dispatch(getProjectDetailError(error.message));
      }
    }
  };
};

export const getProjectStatusParameter = () => {
  return async (dispatch: any) => {
    try {
      const mytoken = localStorage.getItem("token");
      const HEADER = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mytoken,
        },
      };

      const response = await axios.get(
        SERVICEURL + "api/Data/GetTechnoparkProjectStatusParameter?",
        HEADER
      );

      dispatch(getProjectStatusParameterSuccess(response.data));
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        dispatch(getProjectStatusParameterError(error.message));
      }
    }
  };
};

export const getCapacityInfo = () => {
  return async (dispatch: any) => {
    try {
      const mytoken = localStorage.getItem("token");
      const HEADER = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mytoken,
        },
      };

      const response = await axios.get(
        SERVICEURL + "api/Technopark/GetCapacityInfo?",
        HEADER
      );

      dispatch(getCapacityInfoSuccess(response.data));
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      } else {
        dispatch(getCapacityInfoError(error.message));
      }
    }
  };
};

export const getTeknoparkInfo = (technoparkId: number) => async (dispatch: any) => {
  try {
    const response = await axios.post(
      SERVICEURL + "api/Technopark/GetTechnoparkInfo",
      { TechnoparkId: technoparkId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (response.data?.TechnoparkInfoList?.[0]) {
      dispatch({
        type: GET_TEKNOPARK_INFO_SUCCESS,
        payload: response.data.TechnoparkInfoList[0]
      });
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    dispatch({
      type: GET_TEKNOPARK_INFO_ERROR,
      payload: error.message
    });
  }
};

export const getTeknoparkProjects = (technoparkId: number) => async (dispatch: any) => {
  try {
    const response = await axios.post(
      SERVICEURL + "api/Technopark/GetTechnoparkProjectInfoDetail",
      {
        TechnoparkId: technoparkId,
        ProjectId: 0,
        ProjectStatusIds: [],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (response.data) {
      const data = response.data.slice(0, -1);
      dispatch({
        type: GET_TEKNOPARK_PROJECTS_SUCCESS,
        payload: data
      });
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    dispatch({
      type: GET_TEKNOPARK_PROJECTS_ERROR,
      payload: error.message
    });
  }
};

export const getTeknoparkAndProjectInfoSuccess = (data: any): memberActions => ({
  type: GET_TEKNOPARK_AND_PROJECT_INFO_SUCCESS,
  payload: data
});

export const getTeknoparkAndProjectInfoError = (message: string): memberActions => ({
  type: GET_TEKNOPARK_AND_PROJECT_INFO_ERROR,
  payload: message
});

export const getProjectPersonnelSalarySuccess = (data: any): memberActions => ({
  type: GET_PROJECT_PERSONNEL_SALARY_SUCCESS,
  payload: data
});

export const getProjectPersonnelSalaryError = (message: string): memberActions => ({
  type: GET_PROJECT_PERSONNEL_SALARY_ERROR,
  payload: message
});

export const getTeknoparkAndProjectInfo = () => async (dispatch: any) => {
  try {
    const mytoken = localStorage.getItem("token");
    const response = await axios.post(
      SERVICEURL + "api/Technopark/GetTechnoparkAndProjectInfo",
      {
        ProjectStatusId: 2
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mytoken,
        },
      }
    );
    dispatch(getTeknoparkAndProjectInfoSuccess(response.data.TechnoparkAndProjectInfo));
  } catch (error) {
    const err = error as AxiosError;
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      dispatch(getTeknoparkAndProjectInfoError(err.message));
    }
  }
};

export const getProjectPersonnelSalary = (projectId: number) => async (dispatch: any) => {
  try {
    const mytoken = localStorage.getItem("token");
    const response = await axios.post(
      `${SERVICEURL}api/Finance/ListProjectPersonelSalary`,
      {
        projectId: projectId
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mytoken,
        },
      }
    );
    dispatch(getProjectPersonnelSalarySuccess(response.data));
  } catch (error) {
    const err = error as AxiosError;
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      dispatch(getProjectPersonnelSalaryError(err.message));
    }
  }
};
