import {
    DropdownToggle,
    Dropdown
} from "../../../src/components/src/ui/dropdown/dropdown";
import {
    StyledDropMenu,
    MessageItem,
    StyledAuthorName,
    MessageContent,
    MessageDate,
    UnreadBadge
} from "./style"
import EmailIcon from '@mui/icons-material/Email';
import { mockMessages } from "../mockdata"

function Messages() {

    const unreadCount = mockMessages.filter(n => !n.isRead).length;

    return (
        <div>
            <Dropdown direction="down" className="dropdown-profile">
                <DropdownToggle variant="texted">
                    <EmailIcon className="header-icon" />
                    {unreadCount > 0 && <UnreadBadge />}
                </DropdownToggle>
                <StyledDropMenu>
                    {mockMessages.map(message => (
                        <MessageItem
                            key={message.id}
                            className={message.isRead ? 'read' : 'unread'}>
                            <StyledAuthorName>{message.sender}</StyledAuthorName>
                            <MessageContent>{message.content}</MessageContent>
                            <MessageDate>
                                {new Date(message.date).toLocaleString('tr-TR', {
                                    day: 'numeric',
                                    month: 'short',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </MessageDate>
                        </MessageItem>
                    ))}
                </StyledDropMenu>
            </Dropdown>
        </div>
    )
}

export default Messages
