import {DropdownMenu} from "../../../src/components/src/ui/dropdown/dropdown"
import styled, {
  device,
  themeGet,
  css,
} from "../../shared/styled"



export const StyledDropMenu = styled(({ ...rest }) => (
    <DropdownMenu {...rest} />
))`
    max-height: 300px;
    overflow-y: auto;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: ${themeGet("colors.border")};
    width: 230px;
    padding: 10px;
    margin-top: 11.5px;
    box-shadow: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin-right: -10px;
    right: 0;
    left: auto;
    ${device.large} {
        margin-top: 18px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 25px;
        border-bottom: 10px solid ${themeGet("colors.border")};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: auto;
        right: 20px;
        ${device.small} {
            right: 20px;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: -8.5px;
        left: 26px;
        border-bottom: 9px solid #fff;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        left: auto;
        right: 21px;
        ${device.small} {
            right: 21px;
        }
    }
    ${(props) =>
        props.theme.name === "cool" &&
        css`
            margin-top: 13.5px;
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            &::before {
                opacity: 0;
            }
            &::after {
                border-bottom-color: ${themeGet("colors.darklighten5")};
            }
            margin-top: 14px;
        `}
`;

export const StyledUsername = styled.h6`
    color: white;
    font-size: 12px;
    width: max-content;
    margin-bottom: 0px;
    margin-left: 8px;
    ${device.small} {
        display: none; 
    }
    ${device.medium}, ${device.large}, ${device.xlarge}, ${device.xxlarge} {
        display: block; 
    }
`;

StyledDropMenu.displayName = "DropdownMenu";


export const MessageItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${themeGet("colors.border")};
    &:last-child {
        border-bottom: none;
    }

  &.unread {
    background-color: #f8fafc;
    
    p {
      font-weight: 500;
    }
  }


  &.read {
    background-color: transparent;
    
    p {
      font-weight: 400;
    }
  }
`;

export const MessageContent = styled.p`
    font-size: 13px;
    color: ${themeGet("colors.text2")};
    margin-bottom: 5px;
    line-height: 1.4;
`;

export const MessageDate = styled.span`
    font-size: 11px;
    color: ${themeGet("colors.text3")};
    display: block;
    text-align: right;
`;


export const StyledAuthorName = styled.h6`
    font-weight: 600;
    margin-bottom: 5px;
    color:rgb(7, 63, 119);
    font-size: 14px;
`;

export const UnreadBadge = styled.div`
  position: absolute;
  top: 1px;
  right: -2px;
  width: 8px;
  height: 8px;
  background-color: #22C55E;
  border-radius: 50%;
`;