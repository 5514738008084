import { FC, useState, useReducer, useEffect, } from "react";
import { X } from "react-feather";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalFooter,
} from "../../../components/src/ui/modaltwo/modaltwo";
import { Button } from "../../../components";
import { useCommonStyles } from '../../../styles/commonStyles';
import { Row, Col } from "../../../components";
import { SERVICEURL } from "../../../appconfig";
import axios, { AxiosError } from 'axios';
import React from "react";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { TextField } from "@material-ui/core";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  getTechnoparkNameList,
  getProjectNameList,
} from "../../../redux/actions/memberAction";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  show: boolean;
  personelName: any;
  personelId: any;
  technoparkId: any;
  technoparkName: any;
  projectTypeId: any;
  count: any;
  onClose: () => void;

}
const ModalPersonnelUpdate: FC<IProps> = ({
  show,
  personelName,
  personelId,
  technoparkId,
  technoparkName,
  projectTypeId,
  count,
  onClose,
}) => {
  const dis = useDispatch();

  type State = {
    projectId: number;
    projectName: string;
    projectNo: number;
    projectNoId: number;
    projectNoName: string;
    title: string;
    technoparkId: number;
    relatedMemberId: number;
    relatedMemberName: string;
    newProjectStatus: boolean | null;
  };
  const initialState: State = {
    projectId: 0,
    projectName: "",
    projectNo: 0,
    projectNoId: 0,
    projectNoName: "",
    title: "",
    relatedMemberId: 0,
    relatedMemberName: "",
    technoparkId: 0,
    newProjectStatus: null,
  };
  type Action =
    | { type: "setProjectId"; payload: number }
    | { type: "setProjectName"; payload: string }
    | { type: "setTitle"; payload: string }
    | { type: "setProjectNoId"; payload: number }
    | { type: "setProjectNoName"; payload: string }
    | { type: "setTechnoparkId"; payload: number }
    | { type: "setrelatedMemberName"; payload: string }
    | { type: "setNewProjectStatus"; payload: boolean }
    | { type: "setProjectNo"; payload: number }
    | { type: "setrelatedMemberId"; payload: number };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setProjectId":
        return {
          ...state,
          projectId: action.payload,
        };
      case "setProjectName":
        return {
          ...state,
          projectName: action.payload,
        };
      case "setProjectNo":
        return {
          ...state,
          projectNo: action.payload,
        };
      case "setNewProjectStatus":
        return {
          ...state,
          newProjectStatus: action.payload,
        };
      case "setProjectNoId":
        return {
          ...state,
          projectNoId: action.payload,
        };
      case "setProjectNoName":
        return {
          ...state,
          projectNoName: action.payload,
        };
      case "setTitle":
        return {
          ...state,
          title: action.payload,
        };
      case "setTechnoparkId":
        return {
          ...state,
          technoparkId: action.payload,
        };
      case "setrelatedMemberName":
        return {
          ...state,
          relatedMemberName: action.payload,
        };
      case "setrelatedMemberId":
        return {
          ...state,
          relatedMemberId: action.payload,
        };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { technoparkNameList } = useSelector(
    (state: RootStateOrAny) => state.technoparkNameList
  );

  const useStylesSelect = makeStyles((theme: Theme) =>
    createStyles({
      inputControl2: {
        position: "relative",
        width: "100%",
        color: "black",
        height: "54px",
        borderRadius: "4px",
        borderColor: "#AEADAD",
        fontSize: "16px",
      },
      labelControl: {
        position: "relative",
        width: "100%",
        color: "#959393",
        //height: '3.2 rem',
        fontSize: "12px",
      },
    })
  );
  const classesSelect = useStylesSelect();

  const [technoparkValue, setTechnoparkValue] = React.useState<any | null>();
  const [technoparkInputName, setTechnoparkInputName] = useState("");
  const [technoparkOptionName, setTechnoparkOptionName] = useState("");

  const [startMonthYear, setStartMonthYear] = useState<Date | null>();
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openWarningProjectName, setOpenWarningProjectName] = React.useState(false);
  const [updatePersonelName, setUpdatePersonelName] = React.useState("");
  const [updatePersonelId, setUpdatePersonelId] = React.useState(0);
  const [updateTechnoparkName, setUpdateTechnoparkName] = React.useState("");
  const [updateTechnoparkId, setUpdateTechnoparkId] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  function getTechnoparkName() {
    dis(getTechnoparkNameList());
  }
  function getProjectName() {
    dis(getProjectNameList());
  }

  useEffect(() => {
    getTechnoparkName();
    getProjectName();
  }, []);

  const commonStyles = useCommonStyles();

  useEffect(() => {
    setUpdatePersonelName(personelName);
    setUpdatePersonelId(personelId);
    setUpdateTechnoparkId(technoparkId);
    setUpdateTechnoparkName(technoparkName);
  }, [personelName,
    personelId,
    technoparkId,
    technoparkName,
    count]);

  const mytoken = localStorage.getItem("token");

  const handleChangeTechnopark = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setTechnoparkValue(newValue.id);
      dispatch({
        type: "setTechnoparkId",
        payload: newValue.id,
      });

    }
    if (newValue == null) {
      setTechnoparkValue(newValue);
      newValue = 0;
      dispatch({
        type: "setTechnoparkId",
        payload: newValue,
      });
    }
  };

  const handleChangeTechnoparkInputName = (
    event: any,
    newValueInput: any | null
  ) => {
    if (newValueInput != null && newValueInput != undefined) {
      setTechnoparkOptionName(newValueInput);
      setTechnoparkInputName(newValueInput);
    }
    if (newValueInput == null) {
      setTechnoparkInputName(newValueInput);
    }
  };

  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  const handleSave = () => {

    setLoading(true);
    if (technoparkValue === updateTechnoparkId) {
      setOpenWarning(true);
      setLoading(false);
    } else {
      const input = { TeknoparkId: technoparkValue, MemberId: updatePersonelId, NewStartTechnoparkDate: startMonthYear, PersonalTypeId: projectTypeId };
      axios
        .post(SERVICEURL + "api/Technopark/PersonnelTechnoparkChange?", input, HEADER)
        .then((res) => {
          if (res.data.IsOk) {
            setOpen(true);
            setTimeout(() => {
              OnCloseandDelete();
              setLoading(false);
            }, 2000);
          }
        })
        .catch((reason: AxiosError) => {
          setLoading(false);
          if (reason.response && reason.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else:", reason.message);
          }
        });
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    OnCloseandDelete();
  };

  const handleCloseWarning = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarningProjectName(false);
    setOpenWarning(false);
  };


  if (startMonthYear != null) {
    startMonthYear.setHours(5, 0, 0, 0)
  }

  const cancelForm = () => {
    setTechnoparkOptionName("");
    setStartMonthYear(null);
    setUpdatePersonelName("");
    setUpdateTechnoparkName("");
    setUpdatePersonelId(0);
    setUpdateTechnoparkId(0);
    setStartMonthYear(null);
  };
  
  const OnCloseandDelete = () => {
    onClose();
    cancelForm();
    setOpen(false);
    setOpenWarning(false);
  };

  return (
    <>
      <Modal show={show} onClose={OnCloseandDelete} >
        <form>
          <ModalHeader>
            <ModalTitle> Düzenle</ModalTitle>
            <ModalClose onClose={OnCloseandDelete}>
              <X />
            </ModalClose>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} mb={10}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Personel"
                  variant="outlined"
                  inputProps={{ maxLength: 150 }}
                  name="Personel"
                  value={updatePersonelName}
                />
              </Col>

              <Col lg={12} md={12} sm={12} xs={12} mb={10} mt={1}>
                {Object.values(technoparkNameList).length > 0 && (
                  <Autocomplete
                    options={Object.values(
                      technoparkNameList.data.TechnoparkNameList
                    ).map((item: any) => item)}
                    getOptionLabel={(options) =>
                      options.name == undefined
                        ? technoparkOptionName
                        : options.name
                    }
                    value={technoparkValue == null ? 0 : technoparkValue}
                    onChange={handleChangeTechnopark}
                    inputValue={technoparkInputName}
                    onInputChange={handleChangeTechnoparkInputName}
                    disablePortal
                    sx={{ 
                      zIndex: 9999,
                      '& .MuiAutocomplete-popper': {
                        zIndex: 9999
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%", top: "9px" }}
                        variant="outlined"
                        {...params}
                        label="Teknopark Adı"
                      />
                    )}
                    fullWidth
                  />
                )}
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} mb={10} mt={2}>
                <label className={classesSelect.labelControl}>
                  Başlangıç Tarih
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startMonthYear}
                  onChange={(date: Date | null) => setStartMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              className={commonStyles.saveButton}
              onClick={handleSave}
              disabled={loading}
            >
              Kaydet
            </Button>

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert
                onClose={() => handleClose}
                severity="success"
                sx={{ width: "200%" }}
              >
                Değişiklikler kaydedildi.
              </Alert>
            </Snackbar>
            <Snackbar open={openWarning} autoHideDuration={2000} onClose={handleCloseWarning}>
              <Alert
                onClose={() => handleCloseWarning}
                severity="warning"
                sx={{ width: "200%" }}
              >
                Aynı teknoparka kayıt giremezsiniz!!!.
              </Alert>
            </Snackbar>

          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ModalPersonnelUpdate;
