import {
    DropdownToggle,
    Dropdown
} from "../../../src/components/src/ui/dropdown/dropdown";
import {
    StyledDropMenu,
    StyledItem,
    UnreadBadge 
} from "./style"
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mockNotifications } from "../mockdata"

function Notifications() {
    const unreadCount = mockNotifications.filter(n => !n.isRead).length;

    return (
        <div>
            <Dropdown direction="down" className="dropdown-profile">
                <DropdownToggle variant="texted">
                        <NotificationsIcon className="header-icon" />
                        {unreadCount > 0 && <UnreadBadge />}
                </DropdownToggle>
                <StyledDropMenu>
                    {mockNotifications.map(notification => (
                        <StyledItem 
                            key={notification.id} 
                            className={notification.isRead ? 'read' : 'unread'}
                        >
                            <p>{notification.message}</p>
                            <span className="time">{notification.time}</span>
                        </StyledItem>
                    ))}
                </StyledDropMenu>
            </Dropdown>
        </div>
    )
}

export default Notifications;
