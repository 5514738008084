import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import menus from '../../../shared/data/menu';
import {
  SidebarContainer,
  SidebarHeader,
  UserInfo,
  UserInfoCollapsed,
  UserDetails,
  UserName,
  UserRole,
  ToggleContainer,
  ToggleButton,
  SidebarMenu,
  MenuList,
  MenuItem,
  MenuLink,
  MenuIcon,
  MenuItemWithSubmenu,
  MenuItemButton,
  MenuLabel,
  Submenu,
  SubmenuItem,
  SubmenuLink
} from './style';

const userName = localStorage.getItem('userName');

const formatUserName = (userName) => {
  if (!userName) return '';
  return userName
      .replace(/['"]/g, '')
      .split('.')
      .map((word, index) => {
          if (index === 0) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
};

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const sidebarRef = useRef(null);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = (menuId) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [menuId]: !prev[menuId]
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Sayfa değiştiğinde sidebar'ı kapat
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'DashboardCustomize':
        return <DashboardCustomizeIcon />;
      case 'BarChart':
        return <BarChartIcon />;
      case 'TrendingUp':
        return <TrendingUpIcon />;
      case 'ListAlt':
        return <ListAltIcon />;
      case 'AccountCircle':
        return <AccountCircleIcon />;
      default:
        return <DashboardCustomizeIcon />;
    }
  };
  const formattedUserName = formatUserName(userName);

  return (
    <SidebarContainer isOpen={isOpen} ref={sidebarRef}>
      <SidebarHeader>
        {isOpen ? (
          <UserInfo>
            <AccountCircleIcon style={{ fontSize: 34, color: '#5d6b8a' }} />
            <UserDetails>
              <UserName>{formattedUserName}</UserName>
              <UserRole>Software Developer</UserRole>
            </UserDetails>
          </UserInfo>
        ) : (
          <UserInfoCollapsed>
            <AccountCircleIcon style={{ fontSize: 34, color: '#5d6b8a' }} />
          </UserInfoCollapsed>
        )}
      </SidebarHeader>

      <SidebarMenu>
        <MenuList>
          {menus.map((menu) => {
            const validSubmenus = menu.submenu?.filter(item => item.label) || [];
            const hasValidSubmenus = validSubmenus.length > 0;
            
            return (
              <MenuItem key={menu.id} className={menu.url === window.location.pathname ? 'active' : ''}>
                {hasValidSubmenus ? (
                  <MenuItemWithSubmenu>
                    <MenuItemButton onClick={() => isOpen && toggleSubmenu(menu.id)}>
                      <MenuIcon isOpen={isOpen}>{getIcon(menu.icon)}</MenuIcon>
                      {isOpen && (
                        <>
                          <MenuLabel>{menu.label}</MenuLabel>
                          {openSubmenus[menu.id] ? 
                            <KeyboardArrowDownIcon /> : 
                            <KeyboardArrowRightIcon />
                          }
                        </>
                      )}
                    </MenuItemButton>
                    {isOpen && openSubmenus[menu.id] && (
                      <Submenu>
                        {validSubmenus.map((submenu) => (
                          <SubmenuItem key={submenu.id}>
                            <SubmenuLink to={submenu.url}>
                              {submenu.label}
                            </SubmenuLink>
                          </SubmenuItem>
                        ))}
                      </Submenu>
                    )}
                  </MenuItemWithSubmenu>
                ) : (
                  <MenuLink to={menu.url}>
                    <MenuIcon isOpen={isOpen}>{getIcon(menu.icon)}</MenuIcon>
                    {isOpen && <span>{menu.label}</span>}
                  </MenuLink>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </SidebarMenu>
      
      <ToggleContainer>
        <ToggleButton onClick={toggleSidebar}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ToggleButton>
      </ToggleContainer>
    </SidebarContainer>
  );
};

export default Sidebar; 