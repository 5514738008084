const roles = localStorage.getItem('roles');

const menus = [
    {
        id: 1,
        label: "Teknoparklar",
        url: "/teknopark",
        icon: "DashboardCustomize",
    },
    {
        id: 2,
        label: "Projeler",
        url: "/project",
        icon: "BarChart",
    },
    // {
    //     id: 3,
    //     label: "İşe Giriş",
    //     url: "/",
    //     icon: "BarChart",
    //     submenu: [
    //         roles?.includes('TechnoparkEmployeeIntake') ?
    //             {
    //                 id: 21,
    //                 label: "İşe Giriş",
    //                 url: "/newJobStart",
    //             } : {
    //                 id: 21,
    //                 label: "",
    //                 url: "/",
    //             },
              
    //     ]
    // },
    {
        id: 3,
        label: "Personel",
        url: "/personnel",
        icon: "TrendingUp",
        // submenu: [
        //     roles?.includes('TechnoparkProjectAssign') ?
        //         {
        //             id: 31,
        //             label: "Proje Atama",
        //             url: "/projectAssignment",
        //         } : {
        //             id: 31,
        //             label: "",
        //             url: "/",
        //         },
        //     // roles?.includes('TechnoparkLeavingPersonel') ?
        //     //     {
        //     //         id: 32,
        //     //         label: "Ayrılan Personeller",
        //     //         url: "/leavingPersonnel",
        //     //     } : {
        //     //         id: 32,
        //     //         label: "",
        //     //         url: "/",
        //     //     },
        // ]
    },
    {
        id: 4,
        label: "Raporlar",
        url: "/",
        icon: "ListAlt",
        submenu: [
            // roles?.includes('TechnoparkReport') ?
            //     {
            //         id: 41,
            //         label: "Teknopark Rapor",
            //         url: "/technoparkReport",
            //     } : {
            //         id: 41,
            //         label: "",
            //         url: "/",
            //     }, 
                roles?.includes('TechnoparkReport') ?
                {
                    id: 42,
                    label: "Proje Kapanış Raporu",
                    url: "/projectClosingReport",
                } : {
                    id: 42,
                    label: "",
                    url: "/",
                }, 
                roles?.includes('TechnoparkReport') ?
                {
                    id: 43,
                    label: "Proje Personel Raporu",
                    url: "/projectPersonnelReport",
                } : {
                    id: 43,
                    label: "",
                    url: "/",
                }, 
        ]
    }


];

export default menus;
