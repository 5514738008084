import React from "react";
import { useHistory } from "react-router-dom"; 
import { StyledTitle, StyledWrap } from "./style";
import loginmain from "../../static/loginmain.png";
import rdcLogo from "../../static/rdc_logo.png";
import {
    NotFoundContainer,
    NotFoundAlert, 
    Logo,
    NotFoundImageSection,
    Image,
    ImageTitle,
    ImageSubtitle,
    ImageText,
    TurnHomeButton,
    NotFoundTitle
} from './style';

const NotFound: React.FC = () => {
    const history = useHistory(); 

    const handleGoHome = () => {
        history.push("./"); 
    };

    return (
        <NotFoundContainer>
            <NotFoundAlert>
                <Logo src={rdcLogo} alt="RDC Partner Logo" />
                <StyledWrap>
                    <NotFoundTitle>404</NotFoundTitle>
                    <StyledTitle>Aradığınız Sayfa Bulunamadı</StyledTitle>
                    <TurnHomeButton onClick={handleGoHome}>Ana Sayfaya Dön</TurnHomeButton>
                </StyledWrap>
            </NotFoundAlert>
            <NotFoundImageSection>
                <Image bgImage={loginmain} />
                <ImageText>
                    <ImageTitle>Where <span>remote </span>teams</ImageTitle>
                    <ImageTitle>get work done</ImageTitle>
                    <ImageSubtitle>
                        The online collaborative whiteboard platform to bring teams together, anytime, anywhere.
                    </ImageSubtitle>
                </ImageText>
            </NotFoundImageSection>
        </NotFoundContainer>
    );
}
export default NotFound;