import React from 'react';
import {
  Box,
  Typography,
  Grid
} from '@mui/material';
import { BgContainer } from '../style';

interface TeknoparkInfoTabProps {
  teknoparkData: any;
}

const TeknoparkInfoTab: React.FC<TeknoparkInfoTabProps> = ({ teknoparkData }) => {
  return (
    <Box>
      <BgContainer>
        <Typography variant="subtitle1" fontWeight="bold">
          Teknopark Bilgileri
        </Typography>
      </BgContainer>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography color="textSecondary">Kısa Adı</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.ShortName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Alan</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.Area}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Toplam Kapasite</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.TotalCapacity}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="textSecondary">Kira Başlangıç Tarihi</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.RentStartDate}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Kira Bitiş Tarihi</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.RentFinishDate}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Kira</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.Rent || '-'}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="textSecondary">Aidat Başlangıç Tarihi</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.DuesStartDate}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Aidat Bitiş Tarihi</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.DuesFinishDate}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Aidat</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{teknoparkData?.Dues || '-'}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Box>
            <BgContainer>
              <Typography variant="subtitle1" fontWeight="bold">
                Yetkili Bilgileri
              </Typography>
            </BgContainer>

            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Ad Soyad</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{teknoparkData?.RelatedMemberName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Yetkili ID</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{teknoparkData?.RelatedMemberId}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <BgContainer>
              <Typography variant="subtitle1" fontWeight="bold">
                Diğer Bilgiler
              </Typography>
            </BgContainer>

            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Adres</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{teknoparkData?.Address}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Açıklama</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{teknoparkData?.Statement || '-'}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeknoparkInfoTab; 