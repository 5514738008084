import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  IconButton,
  TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Project } from '../types';

interface ProjectsTabProps {
  projectStatus: string;
  setProjectStatus: (status: string) => void;
  capacityStatus: string;
  setCapacityStatus: (status: string) => void;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortedProjects: Project[];
  handleProjectEdit: (project: Project) => void;
  handleProjectView: (project: Project) => void;
  filteredProjects: Project[];
  tableSortProps: any;
}

const ProjectsTab: React.FC<ProjectsTabProps> = ({
  projectStatus,
  setProjectStatus,
  capacityStatus,
  setCapacityStatus,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  sortedProjects,
  handleProjectEdit,
  handleProjectView,
  filteredProjects,
  tableSortProps
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Proje Durum</InputLabel>
          <Select
            value={projectStatus}
            label="Proje Durum"
            onChange={(e) => setProjectStatus(e.target.value)}
            size="small"
            sx={{ minWidth: 200, height: "40px" }}
          >
            <MenuItem value="all">Tümü</MenuItem>
            <MenuItem value="1">Aktif</MenuItem>
            <MenuItem value="2">Pasif</MenuItem>
            <MenuItem value="3">Başvuru Aşamasında</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Boş Kapasite Durumu</InputLabel>
          <Select
            value={capacityStatus}
            label="Boş Kapasite Durumu"
            onChange={(e) => setCapacityStatus(e.target.value)}
            size="small"
            sx={{ minWidth: 200, height: "40px" }}
          >
            <MenuItem value="all">Tümü</MenuItem>
            <MenuItem value="empty">Boş Kapasitesi Olanlar</MenuItem>
            <MenuItem value="full">Boş Kapasitesi Olmayanlar</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '83px' }}>Sıra No</TableCell>
              <tableSortProps.SortableTableHeader
                label="Proje Adı"
                property="Name"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Proje Başlangıç<br/>Tarihi"
                property="ProjectStartDate"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Proje Bitiş<br/>Tarihi"
                property="ProjectEndDate"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Proje Durumu"
                property="ProjectStatusName"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Total Kapasite"
                property="TotalCapacity"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Ar-ge Boş<br/>Kapasite"
                property="ArgeCapacity"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <tableSortProps.SortableTableHeader
                label="Destek Boş<br/>Kapasite"
                property="SupportCapacity"
                sx={{
                  '& .MuiButtonBase-root.MuiTableSortLabel-root': {
                    width: '83px !important',
                    display: 'inline-flex',
                    whiteSpace: 'pre-wrap'
                  }
                }}
              />
              <TableCell sx={{ width: '83px' }}>Min. Maaş</TableCell>
              <TableCell sx={{ width: '83px' }}>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProjects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project: Project, index: number) => (
                <TableRow key={project.ProjectId}>
                  <TableCell>{index + 1 + (page * rowsPerPage)}</TableCell>
                  <TableCell>{project.Name}</TableCell>
                  <TableCell>{project.ProjectStartDate}</TableCell>
                  <TableCell>{project.ProjectEndDate}</TableCell>
                  <TableCell>
                    <Chip
                      label={project.ProjectStatusName}
                      color={project.ProjectStatusId === 1 ? "success" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{project.TotalCapacity}</TableCell>
                  <TableCell>{project.ArgeCapacity}</TableCell>
                  <TableCell>{project.SupportCapacity}</TableCell>
                  <TableCell style={{ width: '100px' }}>4000 TL</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton size="small" onClick={() => handleProjectEdit(project)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleProjectView(project)}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredProjects.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Sayfa başına satır"
      />
    </Box>
  );
};

export default ProjectsTab; 