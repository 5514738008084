import { makeStyles } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles(() => ({
  saveButton: {
    color: "white",
    backgroundColor: "#374E7D",
    border: "none",
    '&:hover': {
      backgroundColor: "#2A3C5F"
    },
    '&:active': {
      backgroundColor: "#374E7D !important",
      boxShadow: 'none !important',
      outline: 'none !important',
      transform: 'none',
      transition: 'none'
    },
    '&:focus': {
      backgroundColor: "#374E7D",
      boxShadow: 'none',
      outline: 'none'
    }
  },
  clearButton: {
    color: "#374E7D",
    backgroundColor: "white",
    border: "1px solid #374E7D",
    '&:hover': {
      backgroundColor: "#f5f5f5"
    },
    '&:active': {
      backgroundColor: "white !important",
      boxShadow: 'none !important',
      outline: 'none !important',
      transform: 'none',
      transition: 'none'
    },
    '&:focus': {
      backgroundColor: "white",
      boxShadow: 'none',
      outline: 'none'
    }
  }
})); 