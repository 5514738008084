import styled, { device } from "../../shared/styled";
import { Table } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import { Col } from "../../components";

export const StyledTable = styled(({ ...rest }) => <Table {...rest} />)``;

export const StyledTheadTR = styled.tr`
  background: #f8f9fa;
  th {
    padding: 0.75rem;
    font-weight: 500;
    border-bottom: 2px solid #dee2e6;
    text-align: left;
    white-space: nowrap;
  }
`;

export const StyledTD = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid #dee2e6;
  white-space: nowrap;
`;

export const Container = styled.div`
  margin: 80px 25px 80px 80px;
      ${device.large} {
        margin: 50px 110px 5px;
    }
`;

export const useStylesButton = makeStyles((theme) => ({
  buttonList: {
    fontWeight: "bold",
    margin: "7px",
  },
  buttonTransfer: {
    fontWeight: "bold",
    margin: "7px",
  },
  buttonExcelTransfer: {
    position: "absolute",
    marginRight: "10px",
    fontWeight: "bold",
    marginTop: "-80px",
    width: "auto",
    color: "#959393",
    height: "2.8rem",
    borderRadius: "4px",
    borderColor: "#AEADAD",
    fontSize: "14px",
    right: 160,
  },
  buttonSave: {
    position: "absolute",
    marginRight: "10px",
    fontWeight: "bold",
    marginTop: "-60px",
    width: "auto",
    height: "2.8rem",
    borderRadius: "4px",
    borderColor: "#AEADAD",
    fontSize: "14px",
    right: 9,
  },
  buttonBottomSave: {
    position: "absolute",
    marginRight: "10px",
    fontWeight: "bold",
    marginBottom: "0px",
    width: "auto",
    height: "2.8rem",
    borderRadius: "4px",
    borderColor: "#AEADAD",
    fontSize: "14px",
    right: 9,
  },
  buttonIcon: {
    marginRight: "5px",
  },
  buttonIconDelete: {
    marginRight: "5px",
    color: "danger",
  },
  TextFieldControl: {
    overflowWrap: "break-word",
    backgroundColor: "rgb(255, 255, 255)",
    backgroundClip: "border-box",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "15px 15px 15px 15px",
    height: "100%",
  },
  colorBackground: {
    backgroundColor: "rgb(247,250,255)",
  },
  expiredRow: {
    backgroundColor: "rgba(255, 0, 0, 0.1)",
  },
  addButton: {
    float: 'right',
    marginRight: '10px'
  },
}));

export const useStylesHeader = makeStyles((theme) => ({
  header: {
    marginLeft: "117px",
    height: "40px",
  },
  scroll: {
    overflowY: "scroll",
    height: "100vh",
    backgroundColor: "white",
  },
  TextFieldControl: {
    overflowWrap: "break-word",
    backgroundColor: "rgb(255, 255, 255)",
    backgroundClip: "border-box",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "15px 15px 15px 15px",
    height: "100%",
  },
  colorBackground: {
    backgroundColor: "white",
  },
}));

export const AddButtonStyles = {


  color: 'white', borderColor: '#374E7D' ,textTransform: 'none',backgroundColor: '#374E7D'

};

export const BoxStyled = styled.div`
  background-color: white;
  border-radius: 12px;
`;
export const GraphContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.06);
`;
export const FlexColumnStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const BgGrid = styled.div`
  background-color: #F4F5F7;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.06);
  margin-top: 45px;
`;
export const BgGridInner = styled.div`
     padding: 20px;
     border-radius: 12px;
     background-color: #F4F5F7;
     display: flex;
     alignItems: center;
     gap: 5px;
`;
