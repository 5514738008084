import React from "react";
import {
    Edit3,
    HelpCircle,
    DollarSign,
    AlertOctagon,
    Command,
   
} from "react-feather";
import {
    DropdownToggle,
    Dropdown
} from "../../ui/dropdown/dropdown";
import{
    Avatar,
    AvatarInitial,
}from "../../ui/avatar/avatar"
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
    StyledUsername,
} from "./style";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {finanslink} from "../../../../appconfig";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const roles = localStorage.getItem('roles');
const logoutChannel = new BroadcastChannel('logout')

export const logOut = () => {
    logoutChannel.postMessage("Logout")
    localStorage.removeItem("token")
    window.location.href =window.location.origin + "/login";
}

export const logoutAllTabs = () =>{
    logoutChannel.onmessage = () => {
        logOut();
        logoutChannel.close();
    }
}

const formatUserName = (userName: string | null) => {
    if (!userName) return '';
    return userName
        .replace(/['"]/g, '')
        .split('.')
        .map((word, index) => {
            if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

const getInitials = (userName: string | null) => {
    if (!userName) return 'RDC';
    return userName
        .replace(/['"]/g, '')
        .split('.')
        .map(word => word.charAt(0).toUpperCase())
        .join('');
};

const ProfileDropdown: React.FC = () => {
    const userName = localStorage.getItem('userName');
    const formattedUserName = formatUserName(userName);
    const initials = getInitials(userName);

    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm">
                    <AvatarInitial>{initials}</AvatarInitial>
                </StyledAvatar>
                <StyledUsername>{formattedUserName}</StyledUsername>
                <ArrowDropDownIcon sx={{color: 'white'}}/>
            </DropdownToggle>
            <StyledDropMenu>
                <Avatar size="lg">
                    <AvatarInitial>{initials}</AvatarInitial>
                </Avatar>
                <StyledAuthorName>{formattedUserName}</StyledAuthorName>
                {/* <StyledAuthorRole>Kullanıcı Rolü</StyledAuthorRole> */}
                {/* <StyledDropItem path="/profile">
                    <Edit3 size="24" />
                    Profili incele
                </StyledDropItem> */}
                {/* <StyledDivider /> */}
                {/* <StyledDropItem
                    
                    path="/help"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Yardım
                </StyledDropItem> */}
                {/* <StyledDropItem path="/profile" mt="10px">
                    <Command size="24" />
                    Hesap ayarları
                </StyledDropItem> */}
                {/* <StyledDropItem path="/profile" mt="10px">
                    <AlertOctagon size="24" />
                    Gizlilik ayarları
                </StyledDropItem> */}
                {roles?.includes('Finance') && (

                <StyledDropItem path={finanslink} mt="10px">
                    <DollarSign size="24" />
                    Finans
                </StyledDropItem>)}               
                <StyledDropItem path="/login" mt="10px">
                {/* <Button onClick ={logOut}
                    variant="contained"
                    color="default"
                    startIcon={<ExitToAppIcon />}
                >
                    ÇIKIŞ YAP
                </Button> */}
                <button onClick={logOut} style={{ fontSize: '14px', padding: '6px 20px', display: 'flex', alignItems: 'center'}}>
                    <ExitToAppIcon style={{ marginRight: '8px' }} /> 
                        ÇIKIŞ YAP
                </button>
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;



