export const mockNotifications = [
    {
      id: 1,
      message: "Yeni proje oluşturdun.",
      time: "Şimdi",
      isRead: false,
    },
    {
      id: 2,
      message: "3 çalışan YTÜ Teknoparka eklendi",
      time: "59 dakika önce",
      isRead: false,
    },
    {
      id: 3,
      message: "Ekolojik ayak izi projesi tamamlandı",
      time: "12 saat önce",
      isRead: true,
    },
    {
      id: 4,
      message: "Ekolojik ayak izi projesine Yeşim Poyraz atandı",
      time: "Bugün, 11:59",
      isRead: true,
    }
  ];

  export const mockMessages = [
    {
        id: 1,
        sender: "Ahmet Yılmaz",
        content: "Merhaba, proje güncellemesini ne zaman gönderebilirsin?",
        date: "2023-05-15T10:30:00",
        isRead: false
    },
    {
        id: 2,
        sender: "Ayşe Kaya",
        content: "Toplantı saatini 14:00'e aldık, katılabilir misiniz?",
        date: "2023-05-15T11:45:22",
        isRead: false
    },
    {
        id: 3,
        sender: "Mehmet Demir",
        content: "Dosyaları gözden geçirdim, birkaç küçük düzeltme var.",
        date: "2023-05-16T09:15:10",
        isRead: true
    },
    {
        id: 4,
        sender: "Zeynep Çelik",
        content: "Haftalık raporu ekte bulabilirsiniz.",
        date: "2023-05-16T16:20:35",
        isRead: false
    },
    {
        id: 5,
        sender: "Can Aydın",
        content: "Yarın ofiste olacak mısın? Birlikte çalışalım.",
        date: "2023-05-17T08:05:47",
        isRead: true
    }
];