import {DropdownMenu} from "../../../src/components/src/ui/dropdown/dropdown"
import styled, {
  device,
  themeGet,
  css,
} from "../../shared/styled"

export const StyledDropMenu = styled(({ ...rest }) => (
    <DropdownMenu {...rest} />
))`
    max-height: 300px;
    overflow-y: auto;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: ${themeGet("colors.border")};
    width: 230px;
    padding: 15px;
    margin-top: 11.5px;
    box-shadow: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin-right: -10px;
    right: 0;
    left: auto;
    ${device.large} {
        margin-top: 18px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 25px;
        border-bottom: 10px solid ${themeGet("colors.border")};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: auto;
        right: 20px;
        ${device.small} {
            right: 20px;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: -8.5px;
        left: 26px;
        border-bottom: 9px solid #fff;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        left: auto;
        right: 21px;
        ${device.small} {
            right: 21px;
        }
    }
    ${(props) =>
        props.theme.name === "cool" &&
        css`
            margin-top: 13.5px;
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            &::before {
                opacity: 0;
            }
            &::after {
                border-bottom-color: ${themeGet("colors.darklighten5")};
            }
            margin-top: 14px;
        `}
`;


StyledDropMenu.displayName = "DropdownMenu";

export const StyledItem = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }

  p {
    margin: 0 0 4px 0;
    font-size: 14px;
    color: #333;
    line-height: 1.4;
  }

  .time {
    display: block;
    font-size: 12px;
    color: #64748b;
    margin-top: 4px;
  }

  &.unread {
    background-color: #f8fafc;
    
    p {
      font-weight: 500;
    }
  }


  &.read {
    background-color: transparent;
    
    p {
      font-weight: 400;
    }
  }
`;


StyledDropMenu.displayName = "DropdownMenu";


export const UnreadBadge = styled.div`
  position: absolute;
  top: 1px;
  right: 0px;
  width: 8px;
  height: 8px;
  background-color: #22C55E;
  border-radius: 50%;
`;