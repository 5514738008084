import { FC, useState, useReducer, useEffect } from "react";
import { X } from "react-feather";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalFooter,
} from "../../../components/src/ui/modal/modal";
import { Button } from "../../../components";
import { Row, Col } from "../../../components";
import { TextField } from "@material-ui/core";
import { useCommonStyles } from '../../../styles/commonStyles';
import FormControl from "@material-ui/core/FormControl";
import { SERVICEURL } from "../../../appconfig";
import axios, { AxiosError } from "axios";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  getTechnoparkNameList,
  getProjectNameList,
} from "../../../redux/actions/memberAction";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  show: boolean;
  projectId: any;
  name: any;
  nameId: any;
  projectNo: any;
  technoparkId: any;
  technoparkName: any;
  relatedMemberId: any;
  relatedMemberName: any;
  argeCapacity: any;
  supportCapacity: any;
  projectStartDate: any;
  projectEndDate: any;
  projectEstEndDate: any;
  projectStatusId: any;
  projectStatusName: any;
  isAdditionalTime: any;
  count:any;
  onClose: () => void;
  getAllProject: () => void;
  getCapacity: () => void;

}

const ModalUpdate: FC<IProps> = ({
  show,
  onClose,
  projectId,
  name,
  nameId,
  projectNo,
  technoparkId,
  technoparkName,
  relatedMemberId,
  relatedMemberName,
  argeCapacity,
  supportCapacity,
  projectStartDate,
  projectEndDate,
  projectEstEndDate,
  projectStatusId,
  projectStatusName,
  isAdditionalTime,
  count,
  getAllProject,
  getCapacity,
}) => {
  const dis = useDispatch();
  const handleChangeProjectName: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    const reg = new RegExp("^[0-9]+$");
    if (reg.test(event.target.value) || event.target.value == "") {
      setProjectName(event.target.value);
    }
    dispatch({
      type: "setProjectName",
      payload: event.target.value,
    });
  };
  const [uprojectNo, setUProjectNo] = useState("");
  const handleChangeProjectNo = (event: any) => {
    const reg = new RegExp("^[0-9]+$");
    if (reg.test(event.target.value) || event.target.value == "") {
      setUProjectNo(event.target.value);
    }
    dispatch({
      type: "setProjectNo",
      payload: event.target.value,
    });
  };
  const [apersonalCapacity, setAPersonalCapacity] = useState("");

  const handleAPersonalCapacity = (event: any) => {
    setAPersonalCapacity(event.target.value);
    dispatch({
      type: "setArgeCapacity",
      payload: event.target.value,
    });
  };
  const [spersonalCapacity, setSPersonalCapacity] = useState("");

  const handleSPersonalCapacity = (event: any) => {
    setSPersonalCapacity(event.target.value);
    dispatch({
      type: "setSupportCapacity",
      payload: event.target.value,
    });

  };

  type State = {
    projectId: number;
    projectName: string;
    projectNo: number;
    projectNoId: number;
    projectNoName: string;
    title: string;
    technoparkId: number;
    technoparkName: string;
    relatedMemberId: number;
    relatedMemberName: string;
    argeCapacity: string;
    supportCapacity: string;
    projectStartDate: string;
    projectEndDate: string;
    newProjectStatus: boolean | null;
    projectStatusName: string;
  };
  const initialState: State = {
    projectId: 0,
    projectName: "",
    projectNo: 0,
    projectNoId: 0,
    projectNoName: "",
    argeCapacity: "",
    supportCapacity: "",
    title: "",
    relatedMemberId: 0,
    relatedMemberName: "",
    technoparkId: 0,
    technoparkName: "",
    projectStartDate: "",
    projectEndDate: "",
    newProjectStatus: null,
    projectStatusName: "",
  };
  type Action =
    | { type: "setProjectId"; payload: number }
    | { type: "setProjectName"; payload: string }
    | { type: "setTitle"; payload: string }
    | { type: "setProjectNoId"; payload: number }
    | { type: "setProjectNoName"; payload: string }
    | { type: "setTechnoparkId"; payload: number }
    | { type: "setTechnoparkName"; payload: string }
    | { type: "setRelatedMemberName"; payload: string }
    | { type: "setNewProjectStatus"; payload: boolean }
    | { type: "setProjectNo"; payload: number }
    | { type: "setRelatedMemberId"; payload: number }
    | { type: "setArgeCapacity"; payload: string }
    | { type: "setProjectStartDate"; payload: string }
    | { type: "setProjectEndDate"; payload: string }
    | { type: "setSupportCapacity"; payload: string }
    | { type: "setProjectStatusName"; payload: string };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setProjectId":
        return {
          ...state,
          projectId: action.payload,
        };
      case "setProjectName":
        return {
          ...state,
          projectName: action.payload,
        };
      case "setProjectNo":
        return {
          ...state,
          projectNo: action.payload,
        };
      case "setNewProjectStatus":
        return {
          ...state,
          newProjectStatus: action.payload,
        };
      case "setProjectNoId":
        return {
          ...state,
          projectNoId: action.payload,
        };
      case "setProjectNoName":
        return {
          ...state,
          projectNoName: action.payload,
        };
      case "setTitle":
        return {
          ...state,
          title: action.payload,
        };
      case "setTechnoparkId":
        return {
          ...state,
          technoparkId: action.payload,
        };
      case "setTechnoparkName":
        return {
          ...state,
          technoparkName: action.payload,
        };
      case "setRelatedMemberName":
        return {
          ...state,
          relatedMemberName: action.payload,
        };
      case "setRelatedMemberId":
        return {
          ...state,
          relatedMemberId: action.payload,
        };
      case "setArgeCapacity":
        return {
          ...state,
          argeCapacity: action.payload,
        };
      case "setSupportCapacity":
        return {
          ...state,
          supportCapacity: action.payload,
        };
      case "setProjectStartDate":
        return {
          ...state,
          projectStartDate: action.payload,
        };
      case "setProjectEndDate":
        return {
          ...state,
          projectEndDate: action.payload,
        };
      case "setProjectStatusName":
        return {
          ...state,
          projectStatusName: action.payload,
        };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { technoparkNameList } = useSelector(
    (state: RootStateOrAny) => state.technoparkNameList
  );
  const { projectNameList } = useSelector(
    (state: RootStateOrAny) => state.projectNameList
  );
  const [period, setPeriod] = useState<Date | null>(new Date());
  const [technoparkValue, setTechnoparkValue] = React.useState<any | null>();
  const [technoparkInputName, setTechnoparkInputName] = useState("");
  const [technoparkOptionName, setTechnoparkOptionName] = useState("");
  const [newProjectStatus, setNewProjectStatus] = React.useState<any | null>();
  const [projectStatusValue, setProjectStatusValue] = React.useState<any | null>();
  const [projectStatusInputName, setProjectStatusInputName] = useState("");
  const [projectStatusOptionName, setProjectStatusOptionName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [relatedMemberValue, setRelatedMemberValue] = React.useState<
    any | null
  >();
  const [relatedMemberİnputName, setRelatedMemberİnputName] =
    React.useState("");
  const [relatedMemberOptionName, setRelatedMemberOptionName] =
    React.useState("");
  //   const [relatedMemberName, setRelatedMemberName] = React.useState("");
  const [startMonthYear, setStartMonthYear] = useState<Date | null>(null);
  const [endMonthYear, setEndMonthYear] = useState<Date | null>(null);
  const [estEndDate, setEstEndDate] = useState<Date | null>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [open, setOpen] = React.useState(false);
  const [openDocumentError, setOpenDocumentError] = React.useState(false);
  const [documentErrorMessage, setDocumentErrorMessage] = useState("");

  const [statusList, setStatusList] = useState<any[]>([]);
  const [additionalDay, setAdditionalDay] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const mytoken = localStorage.getItem("token");

  function getTechnoparkName() {
    dis(getTechnoparkNameList());
  }
  function getProjectName() {
    dis(getProjectNameList());
  }

  const handleChangeStartDate = (strDate: string, date: Date | null) => {
    setStartMonthYear(date)
    dispatch({
      type: "setProjectStartDate",
      payload: strDate,
    });
  }
  const handleChangeEndDate = (endDate: string, date: Date | null) => {
    setEndMonthYear(date)
    dispatch({
      type: "setProjectEndDate",
      payload: endDate,
    });
  }
  useEffect(() => {
    dispatch({
      type: "setProjectId",
      payload: projectId,
    });
    dispatch({
      type: "setProjectName",
      payload: name,
    });
    dispatch({
      type: "setTechnoparkId",
      payload: technoparkId,
    });
    dispatch({
      type: "setTechnoparkName",
      payload: technoparkName,
    });
    dispatch({
      type: "setProjectNo",
      payload: projectNo,
    });
    // dispatch({
    //   type: "setProjectNoName",
    //   payload: name,
    // });
    dispatch({
      type: "setRelatedMemberName",
      payload: relatedMemberName,
    });
    dispatch({
      type: "setRelatedMemberId",
      payload: relatedMemberId,
    });
    dispatch({
      type: "setArgeCapacity",
      payload: argeCapacity,
    });
    dispatch({
      type: "setSupportCapacity",
      payload: supportCapacity,
    });
    dispatch({
      type: "setProjectStartDate",
      payload: projectStartDate,
    });
    dispatch({
      type: "setProjectEndDate",
      payload: projectEndDate,
    });
    state.projectId = projectId;
    state.projectName = name;
    state.technoparkId = technoparkId;
    state.technoparkName = technoparkName;
    state.projectNoId = projectNo;
    // state.projectNoName = name;
    state.relatedMemberName = relatedMemberName;
    state.relatedMemberId = relatedMemberId;
    state.argeCapacity = argeCapacity;
    state.supportCapacity = supportCapacity;
    state.projectStartDate = projectStartDate;
    state.projectEndDate = projectEndDate;
    setPeriod(projectStartDate);
    setProjectStatusValue(projectStatusId);
    setProjectStatusInputName(projectStatusName);
    setProjectStatusOptionName(projectStatusName);
    IsAdditionalTime.additionalTime = isAdditionalTime;
    setUProjectNo(projectNo);
    (isAdditionalTime==true)?setDisabled(false):setDisabled(true)

    if (projectStartDate != "") {
      var dd = projectStartDate?.substring(0, 2);
      var mm = projectStartDate?.substring(3, 5);
      var yyyy = projectStartDate?.substring(6, 10);
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '5:30:00';
      setStartMonthYear(new Date(datee));
    }
    if (projectEndDate != "") {
      var dd = projectEndDate?.substring(0, 2);
      var mm = projectEndDate?.substring(3, 5);
      var yyyy = projectEndDate?.substring(6, 10);
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEndMonthYear(new Date(datee));
    }
    if (projectEstEndDate != "") {
      var dd = projectEstEndDate?.substring(0, 2);
      var mm = projectEstEndDate?.substring(3, 5);
      var yyyy = projectEstEndDate?.substring(6, 10);
      var datee = mm + '/' + dd + '/' + yyyy + ' ' + '23:30:00';
      setEstEndDate(new Date(datee));
    }
    console.log("projectEstEndDate",projectEstEndDate)
    setOpenDocumentError(false);
  }, [
    projectId,
    name,
    technoparkId,
    technoparkName,
    projectNo,
    projectStatusId,
    projectStatusName,
    //projectName,
    relatedMemberName,
    relatedMemberId,
    argeCapacity,
    supportCapacity,
    projectStartDate,
    projectEndDate,
    projectEstEndDate,
    date,
    isAdditionalTime,
    projectNo,
    count
  ]);

  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };
  useEffect(() => {
    axios
      .get(SERVICEURL + "api/Data/GetTechnoparkProjectStatusParameter?", HEADER)
      .then((res) => {

        setStatusList(res.data.ProjectStatusList);

      })
      .catch((reason: AxiosError) => {
        if (reason.response && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else:", reason.message);
        }
      });
  }, []);

  const OnCloseandDelete = () => {
    onClose();
    cancelForm();
  }
  const cancelForm = () => {

    setProjectStatusValue(projectStatusId);
    setProjectStatusInputName(projectStatusName);
    setProjectStatusOptionName(projectStatusName);
    dispatch({
      type: "setProjectStartDate",
      payload: projectStartDate,
    });
    dispatch({
      type: "setProjectEndDate",
      payload: projectEndDate,
    });

    dispatch({
      type: "setArgeCapacity",
      payload: argeCapacity,
    });
    dispatch({
      type: "setSupportCapacity",
      payload: supportCapacity,
    });
    IsAdditionalTime.additionalTime = isAdditionalTime;
    state.projectStartDate = projectStartDate;
    state.projectEndDate = projectEndDate;
    setPeriod(projectStartDate);
    setEstEndDate(null);
    setEndMonthYear(null);
    setStartMonthYear(null);
    setUProjectNo(projectNo);
    setDisabled(true);
    setAdditionalDay(0);
  }
  const handleChangeNewProjectStatus = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setNewProjectStatus(newValue);
      dispatch({
        type: "setNewProjectStatus",
        payload: newValue.props.value,
      });
    }
  };

  useEffect(() => {
    getTechnoparkName();
    getProjectName();
    getAllProject();
  }, []);

  const handleChangeTechnopark = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setTechnoparkValue(newValue.id);
      dispatch({
        type: "setTechnoparkId",
        payload: newValue.id,
      });

      setRelatedMemberİnputName(newValue.relatedMemberName);

      dispatch({
        type: "setRelatedMemberId",
        payload: newValue.relatedMemberId,
      });
      dispatch({
        type: "setRelatedMemberName",
        payload: state.relatedMemberName,
      });
    }
    if (newValue == null) {
      setTechnoparkValue(newValue);
      newValue = 0;
      dispatch({
        type: "setTechnoparkId",
        payload: newValue,
      });
    }
  };

  const handleChangeTechnoparkInputName = (
    event: any,
    newValueInput: any | null
  ) => {
    if (newValueInput != null && newValueInput != undefined) {
      setTechnoparkOptionName(newValueInput);
      setTechnoparkInputName(newValueInput);
    }
    if (newValueInput == null) {
      setTechnoparkInputName(newValueInput);
    }
  };

  const handleChangeProjectStatus = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setProjectStatusValue(newValue.id);
      setProjectStatusInputName(newValue.name);
      setProjectStatusOptionName(newValue.name);

    }
    if (newValue == null) {
      newValue = 0;
      setProjectStatusValue(newValue);
      setProjectStatusInputName("");
      setProjectStatusOptionName("");
    }
  };
  const commonStyles = useCommonStyles();

  const handleChangeProjectStatusInput = (
    event: any,
    newValueInput: any | null
  ) => {
    if (newValueInput != null && newValueInput != undefined) {
      setProjectStatusInputName(newValueInput);
      setProjectStatusOptionName(newValueInput);
    }
    if (newValueInput == null) {
      setProjectStatusInputName("");
      setProjectStatusOptionName("");
    }
  };

  const handleProjectNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    dispatch({
      type: "setProjectName",
      payload: event.target.value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      simulateUpload();
    }
  };

  const simulateUpload = () => {
    setIsUploading(true);
    setUploadProgress(0);
    
    const interval = setInterval(() => {
      setUploadProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 300);
  };

  const removeFile = () => {
    setSelectedFile(null);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const handleSave = () => {
    const mytoken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + mytoken,
      },
    };
    
    const formData = new FormData();
    
    const input = {
      TechnoparkId: state.technoparkId,
      projectId: state.projectId,
      Name: state.projectName,
      ProjectNo: uprojectNo,
      ArgeCapacity: state.argeCapacity,
      SupportCapacity: state.supportCapacity,
      ProjectStartDate: startMonthYear,
      ProjectEndDate: endMonthYear,
      ProjectEstEndDate: estEndDate,
      IsAdditionalTime: IsAdditionalTime.additionalTime,
      ProjectStatusId: projectStatusValue,
    };
    
    if (selectedFile && IsAdditionalTime.additionalTime) {
      formData.append('file', selectedFile);
      formData.append('projectData', JSON.stringify(input));
      
      axios
        .post(
          SERVICEURL + "api/Technopark/UpdateTechnoparkProjectInfoWithFile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + mytoken,
            },
          }
        )
        .then((response) => {
          if (response.data.IsOk) {
            setOpen(true);
            getAllProject();
            getCapacity();
            onClose();
          } else {
            setOpenDocumentError(true);
            setDocumentErrorMessage(response.data.Message);
          }
        })
        .catch((error: AxiosError) => {
          console.log(error.message);
        });
    } else {
      axios
        .post(
          SERVICEURL + "api/Technopark/UpdateTechnoparkProjectInfo",
          input,
          config
        )
        .then((response) => {
          if (response.data.IsOk) {
            setOpen(true);
            getAllProject();
            getCapacity();
            onClose();
          } else {
            console.log(response.data.Message);
            setOpenDocumentError(true);
            setDocumentErrorMessage(response.data.Message);
          }
        })
        .catch((error: AxiosError) => {
          console.log(error.message);
        });
    }
  };
  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenDocumentError(false)
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };
  const OnCloseModal = () => {
    setShowDeleteModal((prev) => !prev);
  };
  const useStylesSelect = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        position: "absolute",
        width: "92%",
        color: "black",
        height: "98%",
        borderRadius: "4px",
        borderColor: "#AEADAD",
        fontSize: "16px",
      },
      inputControl2: {
        position: "relative",
        width: "100%",
        color: "black",
        height: "54px",
        borderRadius: "4px",
        borderColor: "#AEADAD",
        fontSize: "16px",
      },
      labelControl: {
        position: "relative",
        width: "100%",
        color: "#959393",
        fontSize: "12px",
      },
    })
  );
  const classesSelect = useStylesSelect();

  const [IsAdditionalTime, setIsAdditionalTime] = React.useState({
    additionalTime: false,
  });

  const { additionalTime } = IsAdditionalTime;
  const handleChangeAdditionalTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdditionalTime({
      ...IsAdditionalTime,
      [event.target.name]: event.target.checked
    });
    if(event.target.checked==true){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
  };


  const handleAdditionalDay = (event: any) => {
    setAdditionalDay(Number(event.target.value));



    const days = Number(event.target.value);
    setAdditionalDay(days);

    if (estEndDate) {
      const updatedEndDate = new Date(estEndDate);
      updatedEndDate.setDate(updatedEndDate.getDate() + days);
      setEndMonthYear(updatedEndDate);
    }


  };


  useEffect(() => {
    if (estEndDate && endMonthYear) {
      const diffTime = endMonthYear.getTime() - estEndDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setAdditionalDay(diffDays);
    }
  }, [estEndDate, endMonthYear,period]);


  const handleModalClick = () => {
    // Modal içine tıklandığında yapılacak işlemler
  };

  if (estEndDate != null) {
    estEndDate.setHours(5, 0, 0, 0)
  }
  if (endMonthYear != null) {
    endMonthYear.setHours(5, 0, 0, 0)
  }
  if (startMonthYear != null) {
    startMonthYear.setHours(5, 0, 0, 0)
  }

  const useCustomStyles = makeStyles((theme: Theme) =>
    createStyles({
      uploadButton: {
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center'
      },
      uploadIcon: {
        marginRight: '5px'
      },
      closeButton: {
        minWidth: 'auto',
        padding: '5px'
      }
    })
  );
  const customStyles = useCustomStyles();

  return (
    <>
      <Modal show={show} onClose={handleModalClick}>
        <form>
          <ModalHeader>
            <ModalTitle> Proje Kayıt Güncelle</ModalTitle>
            <ModalClose onClose={OnCloseandDelete}>
              <X />
            </ModalClose>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg md sm={11} xs={11} mb={15}>
                <Autocomplete
                  options={technoparkNameList?.data?.TechnoparkNameList || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={technoparkValue}
                  onChange={handleChangeTechnopark}
                  inputValue={state.technoparkName}
                  onInputChange={handleChangeTechnoparkInputName}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      variant="outlined"
                      {...params}
                      label="Teknopark Adı"
                    />
                  )}
                  fullWidth
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  noOptionsText="Teknopark bulunamadı"
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.name}
                    </li>
                  )}
                  disablePortal={true}
                />
              </Col>
              <Col lg md sm={11} xs={11} mb={15}>
                <TextField
                  variant="outlined"
                  id="relatedMemberName"
                  label="Yetkili"
                  value={state.relatedMemberName}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col lg md sm={11} xs={11} mb={15}>
                <TextField
                  variant="outlined"
                  id="projectName"
                  label="Proje Adı"
                  value={state.projectName}
                  onChange={handleChangeProjectName}
                  fullWidth
                />
              </Col>

              <Col lg md sm={11} xs={11} mb={15}>
                <TextField
                  variant="outlined"
                  id="projectNo"
                  label="Proje No"
                  value={uprojectNo}
                  onChange={handleChangeProjectNo}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col lg md sm={11} xs={11} mb={25}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Başlangıç Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={startMonthYear}
                  onChange={(date: Date | null) => setStartMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
              <Col lg md sm={11} xs={11} mb={15}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Tahmini Bitiş Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={estEndDate}
                  onChange={(date: Date | null) => setEstEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
            </Row>
            <Row>
              <Col lg md sm={11} xs={11} mb={15} mt={-1}>
                {Object.values(statusList).length > 0 && (
                  <Autocomplete
                    options={Object.values(statusList).map(
                      (item: any) => item
                    )}
                    getOptionLabel={(options) =>
                      options.name == undefined
                        ? projectStatusOptionName
                        : options.name
                    }
                    value={projectStatusValue == null ? 0 : projectStatusValue}
                    onChange={handleChangeProjectStatus}
                    inputValue={projectStatusInputName}
                    onInputChange={handleChangeProjectStatusInput}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" ,zIndex:0,top:"9px"}}
                        variant="outlined"
                        {...params}
                        label="Proje Durumu"
                      />
                    )}
                    fullWidth
                  />
                )}
              </Col>
              <Col lg md sm={11} xs={11} mb={15} mt={-3}>
                <label className={classesSelect.labelControl}>
                  {" "}
                  Gerçekleşen Bitiş Tarihi{" "}
                </label>
                <DatePicker
                  className={classesSelect.inputControl2}
                  selected={endMonthYear}
                  onChange={(date: Date | null) => setEndMonthYear(date)}
                  dateFormat="dd/MM/yyyy"
                  name=""
                />
              </Col>
            </Row>

            <Row>
              <Col lg md sm={11} xs={11} mb={15}>
                <TextField
                  variant="outlined"
                  id="apersonalcapacity"
                  label="Arge Personel Kapasitesi"
                  value={state.argeCapacity}
                  onChange={handleAPersonalCapacity}
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{zIndex:0,top:"5px"}}
                />
              </Col>
              <Col lg md sm={11} xs={11} mb={15}>
                <TextField
                  variant="outlined"
                  id="spersonalcapacity"
                  label="Destek Personel Kapasitesi"
                  value={state.supportCapacity}
                  onChange={handleSPersonalCapacity}
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={{zIndex:0,top:"5px"}}
                />
              </Col>
            </Row>
            <Row>
            <Col lg={6} md sm xs mb={25} mt={2}>
              <div style={{display:"flex"}}>
                <Col lg={6} md sm xs > 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} p={0}>
                      {!selectedFile ? (
                        <div >
                          <input
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                            <Button
                              className={`${commonStyles.clearButton} ${customStyles.uploadButton}`}
                            >
                              <CloudUploadIcon className={customStyles.uploadIcon} />
                              Dosya Yükle
                            </Button>
              
                          </label>
                        </div>
                      ) : (
                        <div style={{ 
                          border: '1px solid #eee', 
                          borderRadius: '4px', 
                          padding: '10px',
                          backgroundColor: '#f9f9f9'
                        }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img 
                                src="/path/to/pdf-icon.png" 
                                alt="PDF" 
                                style={{ width: '24px', height: '24px', marginRight: '10px' }} 
                              />
                              <div>
                                <div>{selectedFile?.name}</div>
                                <div style={{ fontSize: '12px', color: '#888' }}>{selectedFile ? Math.round(selectedFile.size / 1024) : 0} KB</div>
                              </div>
                            </div>
                            <Button 
                              onClick={removeFile}
                              className={customStyles.closeButton}
                            >
                              <CloseIcon fontSize="small" />
                            </Button>
                          </div>
                          {isUploading && (
                            <div style={{ marginTop: '10px' }}>
                              <div style={{ fontSize: '12px', marginBottom: '5px' }}>
                                Yükleme bekleniyor ... {uploadProgress}%
                              </div>
                              <div style={{ 
                                height: '4px', 
                                backgroundColor: '#eee', 
                                borderRadius: '2px', 
                                overflow: 'hidden' 
                              }}>
                                <div style={{ 
                                  height: '100%', 
                                  width: `${uploadProgress}%`, 
                                  backgroundColor: '#4caf50',
                                  transition: 'width 0.3s ease'
                                }} />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
               </Col>
                <Col lg={6} md sm xs > 
                <Box sx={{ display: "flex" }}>
                      <FormControl component="fieldset" variant="standard" className='' >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={additionalTime}
                              onChange={handleChangeAdditionalTime}
                              name="additionalTime"
                            />
                          }
                          label="Ek süre talebi"
                        />
                      </FormControl>
                    </Box></Col>
                   
                  </div>
                </Col>
                <Col lg={6} md sm xs mb={15}>
                <TextField
                    variant="outlined"
                    id="additionalDay"
                    label="Ek Gün Sayısı"
                    value={additionalDay}
                    disabled={disabled}
                    onChange={handleAdditionalDay}
                    fullWidth
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    style={{zIndex:0,top:"5px"}}
                  />
                </Col>
              </Row>
              
             
              
            </ModalBody>
            <ModalFooter>
            <Button
                size="lg"
                className={commonStyles.clearButton}
                onClick={() => handleDeleteModal()}>
                Temizle
              </Button>
              <Button
                size="lg"
                className={commonStyles.saveButton}
                onClick={handleSave}
              >
                Güncelle
              </Button>

              <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                  onClose={() => handleClose}
                  severity="success"
                  sx={{ width: "200%" }}
                >
                  Değişiklikler kaydedildi.
                </Alert>
              </Snackbar>
              <Snackbar open={openDocumentError} autoHideDuration={10000} onClose={handleClose}>
                <Alert
                  onClose={() => handleClose}
                  severity="error"
                  sx={{ width: "200%" }}
                >
                  {documentErrorMessage}
                </Alert>
              </Snackbar>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
};

export default ModalUpdate;
