import styled, {
    device,
    layout,
    space,
    css,
} from "../../shared/styled";

export const StyledHeader = styled.header`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-items: stretch;
    height: 55px;
    padding: 0;
    justify-content: flex-start;
     background-color: #384c7c;
    border-bottom: 1px solid #485e9029;
     position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9992;
 
        height: 60px;
       
        text-decoration: none;
        .navbar{
        display: flex;
       gap: 20px;
       justify-content: center;
       align-items: center;
       }
    .navbar    li a {
            color: #fff;
        }
    .navbar li a:hover {
            color: #bfbdb6;
        }
            .navbar .submenu li a {
                color:rgb(119, 119, 118);
            }
            .navbar .submenu li a:hover {
                color: #bfbdb6;
            }

    
    ${(props) =>
        props.theme.name === "cool" &&
        css`
            text-decoration: none;
            border-bottom-color: #0168fa;
            box-shadow: 0 0 25px #0168fa;
        `};
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: #1c273c;
            border-bottom-width: 0;
        `};
`;

export const StyledLogo = styled.div`
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-right: 0;
  order: 1;

  ${device.small} {
    margin-top: 6px;
    padding-left: 2px;
    width: 40%;
  }

  ${device.medium} {
    width: 30%;
    padding-left: 10px;
    margin-bottom: 1rem;
  }

  ${device.large}, ${device.xlarge}, ${device.xxlarge} {
    width: 240px;
    padding: 0 0 0 20px;
  }
`;

interface INavbar {
    $isOpen?: boolean;
}

export const StyledNavbarWrap = styled.div<INavbar>`
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    flex: 1;
    
    ${device.large} {
        max-width: 500px;
    }
`;


export const StyleNavbarRight = styled.div`
    padding: 0 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2;

    @media screen and (width: 360px) {
    margin-top: -50px;
     }

    ${device.small} {
        .header-icon {
        width: 5px;
        height: 5px;
        stroke-width: 2.25px;
      }
    }

    ${device.medium} {
        width: 250px;
        margin-bottom: 10px;
    }

    ${device.large} {
        order: 3;
        padding: 0 20px 0 0;
        gap: 10px;
        margin-bottom: 0;
    }

    ${device.xlarge}, ${device.xxlarge} {
        width: 240px;
        margin-bottom: 0;
    } 
  }
`;

export const NAV_ELEMENT_MARGINS = {
    default: ["10px", "15px", "20px"],
    notification: ["10px", "15px", "0px"]
} as const;

export const StyledNavbarElement = styled(({ mt, mb, ml, mr, ...props }) => (
    <div {...props} />
))`
    ${space};
    ${layout};
    .header-icon {
        width: 16px;
        height: 16px;
        stroke-width: 3px;
        color: #fff;
        ${device.small} {
            width: 15px;
            height: 15px;
            stroke-width: 2.25px;
        }
        ${device.medium}, ${device.large}, ${device.xlarge}, ${device.xxlarge} {
            width: 20px;
            height: 20px;
            stroke-width: 3px;
        }
        ${({ theme }) =>
        theme.name === "dark" &&
        css`
                color: #cdd4e0;
                &:hover,
                &:focus {
                    color: #ffffff;
                }
            `}
    }
`;

export const StyledSearchBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;

    ${device.small} {
        display: none;
    }

    ${device.medium} {
        display: flex;
        margin: 0 auto;
        width: 100%; 
        margin-left: 0; 
        margin-bottom: 10px;

        .MuiTextField-root {
            height: 2.8em; 
        }

        .MuiOutlinedInput-root {
            height: 2.8em; 
        }

        .MuiInputAdornment-root svg {
            font-size: 1.5em;
        }
    }
    
    ${device.large}, ${device.xlarge} ,${device.xxlarge}{
        display: flex;
        margin: 0 auto;
        width: 100%; 
        margin-left: 0; 
        
        .MuiTextField-root {
            height: auto; 
        }

        .MuiOutlinedInput-root {
            height: auto;
        }

        .MuiInputAdornment-root svg {
            font-size: 1.5em; 
        }
    }
    
    .MuiTextField-root {
        background-color: #fff;
        border-radius: 4px;
    }
    
    .MuiOutlinedInput-root {
        border-radius: 4px;
        
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #384c7c;
        }
    }
    
    .MuiInputAdornment-root {
        color: #8392a5;
    }
`;
export const StyledVerticalDivider = styled.div`
    height: 30px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 10px;
    align-self: center;
`;
