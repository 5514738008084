import { combineReducers } from "redux";
import {
  costCenterReducer,
  companiesReducer,
  customersReducer,
  passiveCustomerReducer,
  personnelNameForProjectAssignmentListReducer,
  currentDebtReducer,
  myUserDetailReducer,
  mainCostCenterReducer,
  subCostCenterReducer,
  allCostCenterReducer,
  costCenterDetailReducer,
  firmReducer,
  startingWorkEmployeesReducer,
  personalTypeListReducer,
  technoparkNameListReducer,
  projectNameListReducer,
  teknoparkListReducer,
  projectAssignmentListReducer,
  leavingPersonalListReducer,
  relatedMemberNameListReducer,
  teknoparkUpdateReducer,
  allTechnoparkNameListReducer,
  barChartReducer,
  pieChartReducer,
  teknoparkDetailReducer,
  projectDetailReducer,
  projectStatusParameterReducer,
  capacityInfoReducer,
  teknoparkInfoReducer,
  teknoparkProjectsReducer,
  teknoparkAndProjectInfoReducer,
  projectPersonnelSalaryReducer,
} from "./memberList";
import uiReducer from "../slices/ui";
//import cartReducer from "./memberList";

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  ui: uiReducer,
  costCenter: costCenterReducer,
  companies: companiesReducer,
  customerList: customersReducer,
  passiveCustomerList: passiveCustomerReducer,
  currentDebt: currentDebtReducer,
  myUserDetail: myUserDetailReducer,
  mainCostCenter: mainCostCenterReducer,
  subCostCenter: subCostCenterReducer,
  allCostCenter: allCostCenterReducer,
  costCenterDetail: costCenterDetailReducer,
  firm: firmReducer,
  startingWorkEmployees: startingWorkEmployeesReducer,
  personalTypeList: personalTypeListReducer,
  technoparkNameList: technoparkNameListReducer,
  personnelNameForProjectAssignmentList:
    personnelNameForProjectAssignmentListReducer,
  relatedMemberNameList: relatedMemberNameListReducer,
  projectNameList: projectNameListReducer,
  teknoparkList: teknoparkListReducer,
  teknoparkUpdateList: teknoparkUpdateReducer,
  projectAssignmentList: projectAssignmentListReducer,
  leavingPersonalList: leavingPersonalListReducer,
  allTechnoparkNameList: allTechnoparkNameListReducer,
  barChartData: barChartReducer,
  pieChartData: pieChartReducer,
  teknoparkDetail: teknoparkDetailReducer,
  projectDetail: projectDetailReducer,
  projectStatusParameter: projectStatusParameterReducer,
  capacityInfo: capacityInfoReducer,
  teknoparkInfo: teknoparkInfoReducer,
  teknoparkProjects: teknoparkProjectsReducer,
  teknoparkAndProjectInfo: teknoparkAndProjectInfoReducer,
  projectPersonnelSalary: projectPersonnelSalaryReducer,
});

export default rootReducer;
